import request from "@/utils/request";/*  */

/**
 * 大件无忧账户余额
 */
export function getGasCardAccBalance() {
  return request.get("/oilCard/api/czx-total-amount", {});
}

/**
 * 大件无忧今天充值总和
 */
export function getGasCardAllBalance() {
  return request.get("/oilCard/api/czx-evey-amount", {});
}

/**
 * 查询车主邦加油金额
 */
export function getGasCardczbBalance() {
  return request.get("/oilCard/api/order-czb-amount", {});
}

/**
 * 查询山东高速加油金额
 */
export function getGasCardetsdBalance() {
  return request.get("/oilCard/api/order-etcsd-amount", {});
}

/**
 * 查询万金油加油金额
 */
export function getGasCardwjyBalance() {
  return request.get("/oilCard/api/order-wjy-amount", {});
}


/**
 * 老吕加油每日加油金额
 */
export function getGasCardLVBalance() {
  return request.get("/oilCard/api/order-zyw-amount", {});
}

/**
 * 每月充值金额
 */
export function getGasCardMonthRechargeBalance() {
  return request.get("/oilCard/api/czx-month-amount", {});
}

/**
 * 每日消费金额
 */
export function getGasCardDayConsumeBalance() {
  return request.get("/oilCard/api/czx-evey-consume", {});
}

/**
 * 每月消费金额
 */
export function getGasCardMonthConsumeBalance() {
  return request.get("/oilCard/api/czx-month-consume", {});
}


/*****************/

/**
 * 获取充值金额总和
 */
export function getGasRechargeAllBalance() {
  return request.get("/oilCard/api/rechargesTotalAmount", {});
}


/**
 * 获取转卡总和
 */
export function getGasTransferAllBalance() {
  return request.get("/oilCard/api/gas-card-totalbalance", {});
}



/**
 * 折线图
 */
export function getGaslengend() {
  return request.get("/oilCard/api/gas-summaries", {});
}



