import request from "@/utils/request";
/**
 * 获取加油卡转账列表
 */
//export function getGasTransferList(params) {
//return request.get("/api/gas-transfers", { params: { params } });
//}

//export function getGasTransferList(startAddTime, endAddTime, fromMobile, fromCompanyName, fromGasCardNo, toUserRealName, toGasCarNo) {
//return request.get("/api/gas-transfers?startAddTime="+startAddTime+"&endAddTime="+endAddTime+"&fromMobile="+fromMobile+"&fromCompanyName="+fromCompanyName+"&fromGasCardNo="+fromGasCardNo+"&toUserRealName="+toUserRealName+"&toGasCarNo="+toGasCarNo, {});
//}

export function getGasTransferList(startAddTime, endAddTime, fromMobile, fromGasCardNo, toUserRealName, toGasCarNo, size, page) {
	return request({
	    url: "/oilCard/api/gas-transfers",
	    method: "get",
	    params: {
	      startAddTime:startAddTime,
	      endAddTime:endAddTime,
	      fromMobile:fromMobile,
	      fromGasCardNo:fromGasCardNo,
	      toUserRealName:toUserRealName,
	      toGasCarNo:toGasCarNo,
	      size:size, 
	      page:page
	    }
	})
}

/**
 * 获取加油卡转账详情
 */
export function getGasTransfer(id) {
  return request.get("/oilCard/api/gas-transfer/"+id, {});
}
