import request from "@/utils/request";
/**
 * 获取加油站列表
 */
//export function getGasStationList(name, gasStationId, address, type, way, brandType,oilNo) {
//return request.get("/api/gas-stations?name="+name+"&gasStationId="+gasStationId+"&address="+address+"&type="+type+"&way="+way+"&brandType="+brandType+"&oilNo="+oilNo, {});
//}
export function getGasStationList(name, gasStationId, type, way, brandType, oilNo, size, page, provinceId, cityId, districtId, ifCzbStatus, ifEtcsdStatus, ifWjyStatus, ifZywStatus) {
	return request({
	    url: "/oilCard/api/gas-stations",
	    method: "get",
	    params: {
	      name:name,
	      gasStationId:gasStationId,
	      // address:address,
	      type:type,
	      way:way,
	      brandType:brandType,
	      oilNo:oilNo,
	      size:size, 
				page: page,
			    provinceId:provinceId, //省份ID
				cityId:cityId, //市ID
			    districtId:districtId,//区县ID
				ifCzb:ifCzbStatus,//是否车主邦油站
				ifEtcsd:ifEtcsdStatus,//是否山东高速信联支付油站
				ifWjy:ifWjyStatus,//是否万金油支付油站
				ifZyw: ifZywStatus,//是否老吕支付油站
				ifDisplay:true
	    }
	})
}


/**
 * 全部屏蔽和显示
 */
export function getGasStationBtn(ifShow, ifCzb, ifEtcsd, ifWjy, ifZyw) {
	return request({
	    url: "/oilCard/api/gas-stations/ifShow",
	    method: "post",
	    data: {
			ifShow:ifShow,
			ifCzb:ifCzb,
			ifEtcsd:ifEtcsd,
			ifWjy:ifWjy,
			ifZyw:ifZyw
	    }
	})
}


/**
 * 获取加油站详情
 */
export function getGasStation(id) {
  return request.get("/oilCard/api/gas-station/"+id, {});
}

/**
 * 修改加油站详情信息
 * @param id 加油站ID
 */
export function updateGasStation(id, data) {
	return request({
			url: "/oilCard/api/gas-station/"+id,
			method: "post",
			data: {
				data
			}
	});
}

//显示和不显示
export function showGasStation(id,valueStatus) {
	return request({
			url: "/oilCard/api/gas-station/"+ id +"/save",
			method: "post",
			data: {
				ifShow:valueStatus
			}
	})
}