import request from "@/utils/request";

/**
 * 获取提现列表
 */
export function getWithdrawList(startAddTime, endAddTime, no, mobile, realname, size, page) {
  return request.get("/oilCard/api/withdraws", {
  	  	params: {
	  		startAddTime:startAddTime,
	  		endAddTime:endAddTime,
	  		no:no,
		  	mobile:mobile,
		  	realname:realname,
		    size:size,
		  	page:page
		}
  });
}


/**
 * 获取提现详情信息
 */
export function getWithdraw(id) {
  return request.get("/oilCard/api/withdraw/${id}", {});
}


/**
 * 审核提现信息
 *
 * @param id 提现ID
 * @param result 提现结果（success,failure）
 */
export function auditWithdraw(id, result) {
  return request({
    url: "/oilCard/api/withdraw/"+ id +"/audit/"+result,
    method: "post",
    data:{
    	content:""
    }
  });
}
