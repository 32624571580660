<template>
	<div class="app-main">
	  <div class="body">
	   	      <el-form inline class="my-form">   	
					      <el-form-item label="油站名称：">
					         <el-input size="small" placeholder=""  v-model="name"></el-input>
					      </el-form-item>
					      
					      <el-form-item label="油站ID：">
					         <el-input size="small" placeholder=""  v-model="gasStationId"></el-input>
					      </el-form-item>
					      
					      <el-form-item label="油站地址：">
                   <v-distpicker @selected="onSelected"></v-distpicker>
					      </el-form-item>
					      
					      <el-form-item label="油站类型：" >
					         <v-select :selectData="oilStationTypeList"  :selectID="oilstationTypeID"  @choonseGrageEvent="chooseoilstation"></v-select> 
					      </el-form-item>
					      
					      <el-form-item label="道路类型：" >
					         <v-select :selectData="wayTypeList"  :selectID="oilWayTypeID"  @choonseGrageEvent="chooseoilWay"></v-select> 
					      </el-form-item>
					      
					      <el-form-item label="品牌类型：" >
					        <v-select :selectData="brandTypeList"  :selectID="oilbrandTypeID"  @choonseGrageEvent="chooseoilbrand"></v-select> 
					      </el-form-item>
					      
					      <el-form-item label="油品名称：">
					         <el-input size="small" placeholder="" v-model="oilNo"></el-input>
					      </el-form-item>
					      
					      <el-form-item>
					         <el-button size="small" type="primary" @click.stop="findData">查询</el-button>
					         <el-button size="small" @click.stop="resetBtn">重置</el-button>
					         <el-button size="small" disabled>导出</el-button>
					      </el-form-item>
				    </el-form>
       </div>
       <div class="body m-t20">
	       	<div class="btn_po">
  		  		<el-radio-group v-model="cardType">
			          <el-radio-button label="全部"></el-radio-button>
			          <el-radio-button label="车主邦"></el-radio-button>
		            <el-radio-button label="山东高速"></el-radio-button>
		            <el-radio-button label="万金油"></el-radio-button>
                <el-radio-button label="老吕加油"></el-radio-button>
			    </el-radio-group>
		  	</div>

		    <page-table :data="tableData" :columns="columns" :pagination="pagination"  :rowClassName="rowClassName"  v-if="cardType=='全部'" :loading="loading" @changeStatus="changeStatus"  @changeSwitch="changeSwitch"  @current-change="currentPageNum" @handleSelectionChange="handleSelectionChange" :tableName="tableName">
          <el-table-column label="操作" align="center">
                <template slot-scope="scope">
		            <el-tag  @click.stop="editStation(scope.$index, scope.row)"  class="btnlist">编辑</el-tag>
		        </template>
		      </el-table-column>
		    </page-table>

		    <page-table :data="tableDataSec" :columns="columns1" :pagination="pagination" :rowClassName="rowClassName" v-if="cardType=='车主邦'" :loading="loading" @changeStatus="changeStatus" @changeSwitch="changeSwitch" @size-change="pageSizeNum"  @current-change="currentPageNum" @handleSelectionChange="handleSelectionChange" :tableName="tableName">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		            <el-tag @click.stop="editStation(scope.$index, scope.row)" class="btnlist">编辑</el-tag>
		          </template>
		        </el-table-column>
		    </page-table>
        
		    <page-table :data="tableDataThi" :columns="columns2" :pagination="pagination" :rowClassName="rowClassName" v-if="cardType=='山东高速'" :loading="loading" @changeStatus="changeStatus" @changeSwitch="changeSwitch" @size-change="pageSizeNum"  @current-change="currentPageNum" @handleSelectionChange="handleSelectionChange" :tableName="tableName">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		            <el-tag @click.stop="editStation(scope.$index, scope.row)" class="btnlist">编辑</el-tag>
		          </template>
		        </el-table-column>
		    </page-table>

		    <page-table :data="tableDataFou" :columns="columns3" :pagination="pagination" :rowClassName="rowClassName" v-if="cardType=='万金油'" :loading="loading" @changeStatus="changeStatus" @changeSwitch="changeSwitch" @size-change="pageSizeNum"  @current-change="currentPageNum" @handleSelectionChange="handleSelectionChange" :tableName="tableName">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		             <el-tag @click.stop="editStation(scope.$index, scope.row)" class="btnlist">编辑</el-tag>
		          </template>
		        </el-table-column>
		     </page-table>

         <page-table :data="tableDataFiv" :columns="columns4" :pagination="pagination" :rowClassName="rowClassName" v-if="cardType=='老吕加油'" :loading="loading" @changeStatus="changeStatus" @changeSwitch="changeSwitch" @size-change="pageSizeNum"  @current-change="currentPageNum" @handleSelectionChange="handleSelectionChange" :tableName="tableName">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		             <el-tag @click.stop="editStation(scope.$index, scope.row)" class="btnlist">编辑</el-tag>
		          </template>
		        </el-table-column>
		     </page-table>
       </div>
  </div>
</template>
<script>
  import { getGasStationList, getGasStationBtn, getGasStation, updateGasStation, showGasStation } from "@/api/station";
  import pageTable from '../../components/pageTable'
  import VSelect from '../../components/select'
  import VDistpicker from 'v-distpicker'
  export default {
    name: 'flowDetail',
    inject:['reload'],
    data:function() {
      return {
      	cardType: '全部',
        tableData: [],
        tableDataSec: [],
        tableDataThi: [],
        tableDataFou: [],
        tableDataFiv: [],
        oilStationTypeList: [{
          value: 1,
          label: '中石油'
        }, {
          value: 2,
          label: '中石化',
        }, {
          value: 3,
          label: '壳牌'
        },{
          value: 4,
          label: '其他'
        }],
        
        wayTypeList: [{
          value: 1,
          label: '高速'
        }, {
          value: 2,
          label: '国道',
        }, {
          value: 3,
          label: '其他'
        }],
        
        brandTypeList: [{
          value: 1,
          label: '国营央企'
        }, {
          value: 0,
          label: '民营私企',
    
        }, {
          value: -1,
          label: '外企/合资'
        }],
        
        columns: [
          {
            name: '油站ID',
            value: 'gasStationId',
           width: '150'
          },
          {
            name: '油站名称',
            value: 'name',
            width: '280'
          },
          {
            name: '油站地址',
            value: 'address',
            width: '280'
          },
          {
            name: '合作伙伴',
            value: 'cooperativePartner',
            width: '300'
          },
          {
            name: '道路类型',
            value: 'oilstationway'
          },
          {
            name: '油站类型',
            value: 'oilStationtype'
          },
          {
            name: '品牌类型',
            value: 'oilStationbrand_type'
          },
          {
            name: '包含油品',
            value: 'oilNos'
          },
          {
            name: '最低价格',
            value: 'minPrice'
          }
        ],
        
        columns1: [
          {
            name: '油站ID',
            value: 'gasStationId',
            width: '150'
          },
          {
            name: '油站名称',
            value: 'name',
            width: '280'
          },
          {
            name: '油站地址',
            value: 'address',
            width: '280'
          },
          {
            name: '道路类型',
            value: 'way'
          },
          {
            name: '油站类型',
            value: 'oilStationtype'
          },
          {
            name: '品牌类型',
            value: 'oilStationbrand_type'
          },
          {
            name: '包含油品',
            value: 'oilNos'
          },
          {
            name: '最低价格',
            value: 'minPrice'
          }
        ],
        
        columns2: [
          {
            name: '油站ID',
            value: 'gasStationId',
           width: '150'
          },
          {
            name: '油站名称',
            value: 'name',
            width: '280'
          },
          {
            name: '油站地址',
            value: 'address',
            width: '280'
          },
          {
            name: '道路类型',
            value: 'way'
          },
          {
            name: '油站类型',
            value: 'oilStationtype'
          },
          {
            name: '品牌类型',
            value: 'oilStationbrand_type'
          },
          {
            name: '包含油品',
            value: 'oilNos'
          },
          {
            name: '最低价格',
            value: 'minPrice'
          }
        ],
        
        columns3: [
          {
            name: '油站ID',
            value: 'gasStationId',
           width: '150'
          },
          {
            name: '油站名称',
            value: 'name',
            width: '280'
          },
          {
            name: '油站地址',
            value: 'address',
            width: '280'
          },
          {
            name: '道路类型',
            value: 'way'
          },
          {
            name: '油站类型',
            value: 'oilStationtype'
          },
          {
            name: '品牌类型',
            value: 'oilStationbrand_type'
          },
          {
            name: '包含油品',
            value: 'oilNos'
          },
          {
            name: '最低价格',
            value: 'minPrice'
          }
        ],

        columns4: [
          {
            name: '油站ID',
            value: 'gasStationId',
            width: '150'
          },
          {
            name: '油站名称',
            value: 'name',
            width: '280'
          },
          {
            name: '油站地址',
            value: 'address',
            width: '280'
          },
          {
            name: '道路类型',
            value: 'way'
          },
          {
            name: '油站类型',
            value: 'oilStationtype'
          },
          {
            name: '品牌类型',
            value: 'oilStationbrand_type'
          },
          {
            name: '包含油品',
            value: 'oilNos'
          },
          {
            name: '最低价格',
            value: 'minPrice'
          },
        ],

        loading:false,
        pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage:0,
          size: 0
        },
        name:'',
        gasStationId:'', 
        address:'', 
        oilstationTypeID:'',
        oilWayTypeID:'',
        oilbrandTypeID:'',
        oilNo:'',
        selectRow: [],//选中的行
        selectData: [],//选中的数据,
        tableName:"gasStation",

        ifCzbStatus:null, 
        ifEtcsdStatus:null, 
        ifWjyStatus:null, 
        ifZywStatus:null,

        provinceId:'',//省份ID
        cityId:'',//市ID
        districtId:'',//区县ID
        switchNum: true,
        ifCzb:true,
        ifEtcsd:true,
        ifWjy:true,
        ifZyw:true
      }
    },
    components: { VDistpicker,pageTable,VSelect },
    created() {
      document.title = '油站管理'
      //this.$store.dispatch('SetNavbar', [{ meta: { title: '管理中心' }, path: '/oilCard-manage/index' }, { meta: { title: '油卡管理' }, path: '/oilCard-manage/manage' }, { meta: { title: '油站管理' } }])
    },
    methods:{
      //查询
    	findData(){
        if(this.cardType=='全部'){
          this.ifCzbStatus= null
          this.ifEtcsdStatus= null
          this.ifWjyStatus= null 
          this.ifZywStatus= null 
          this.pagination.currPage=0
          this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize, this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus, this.ifEtcsdStatus, this.ifWjyStatus, this.ifZywStatus)
        }else if(this.cardType=='山东高速'){
          this.ifCzbStatus= null
          this.ifEtcsdStatus= true
          this.ifWjyStatus= null 
          this.ifZywStatus= null 
          this.pagination.currPage=0
          this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize, this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus, this.ifEtcsdStatus, this.ifWjyStatus, this.ifZywStatus)
       }else if(this.cardType=='万金油'){
          this.ifCzbStatus= null
          this.ifEtcsdStatus= null
          this.ifWjyStatus= true
          this.ifZywStatus= null 
          this.pagination.currPage=0
          this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize, this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus, this.ifEtcsdStatus, this.ifWjyStatus, this.ifZywStatus)
        }else if(this.cardType=='老吕加油'){

          this.ifCzbStatus= null
          this.ifEtcsdStatus= null
          this.ifWjyStatus= null
          this.ifZywStatus= true 
          this.pagination.currPage=0
          this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize, this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus, this.ifEtcsdStatus, this.ifWjyStatus, this.ifZywStatus)
        }else{
          this.ifCzbStatus= true
          this.ifEtcsdStatus= null
          this.ifWjyStatus= null
          this.ifZywStatus= null
          this.pagination.currPage=0
          this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize, this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus, this.ifEtcsdStatus, this.ifWjyStatus, this.ifZywStatus)
       }
     },

   	  onSelected(data) {
            // console.log(data.area.code)
            // console.log(data.city.code)
            // console.log(data.province.code)
            this.provinceId=data.province.code //省份ID
            this.cityId=data.city.code //市ID
            this.districtId= data.area.code//区县ID
	    }, 

      // 点击全部关闭和启动油站 手动
      changeSwitch(){
        console.log(this.cardType)
         if(this.cardType=='全部'){
                if(this.switchNum == true){
                  this.switchNum = false
                  this.ifCzb=null
                  this.ifEtcsd=null
                  this.ifWjy=null
                  this.ifZyw=null
                  this.ShieldingButton(this.switchNum, this.ifCzb, this.ifEtcsd, this.ifWjy, this.ifZyw)
                }else{
                  
                  this.switchNum = true
                  this.ifCzb=null
                  this.ifEtcsd=null
                  this.ifWjy=null
                  this.ifZyw=null
                  this.ShieldingButton(this.switchNum, this.ifCzb, this.ifEtcsd, this.ifWjy, this.ifZyw)
                }
                this.$message({
                  message: '修改成功',
                  type: 'success'
                });

         }else if(this.cardType=='山东高速'){
              if(this.switchNum == true){
                  this.switchNum = false
                  this.ifCzb=null
                  this.ifEtcsd=true
                  this.ifWjy=null
                  this.ifZyw=null
                  this.ShieldingButton(this.switchNum, this.ifCzb, this.ifEtcsd, this.ifWjy, this.ifZyw)
                }else{
                   this.switchNum = true
                   this.ifCzb=null
                   this.ifEtcsd=true
                   this.ifWjy=null
                   this.ifZyw=null
                   this.ShieldingButton(this.switchNum, this.ifCzb, this.ifEtcsd, this.ifWjy, this.ifZyw)
                }
                this.$message({
                  message: '修改成功',
                  type: 'success'
                });
         }else if(this.cardType=='万金油'){
                if(this.switchNum == true){
                  this.switchNum = false
                  this.ifCzb=null
                  this.ifEtcsd=null
                  this.ifWjy=true
                  this.ifZyw=null
                  this.ShieldingButton(this.switchNum, this.ifCzb, this.ifEtcsd, this.ifWjy, this.ifZyw)
                }else{
                  this.switchNum = true
                  this.ifCzb=null
                  this.ifEtcsd=null
                  this.ifWjy=true
                  this.ifZyw=null
                  this.ShieldingButton(this.switchNum, this.ifCzb, this.ifEtcsd, this.ifWjy, this.ifZyw)
                }
                this.$message({
                      message: '修改成功',
                      type: 'success'
                });
         }else if(this.cardType=='老吕加油'){
                if(this.switchNum == true){
                  this.switchNum = false
                  this.ifCzb=null
                  this.ifEtcsd=null
                  this.ifWjy=null
                  this.ifZyw=true
                  this.ShieldingButton(this.switchNum, this.ifCzb, this.ifEtcsd, this.ifWjy, this.ifZyw)
                }else{
                  this.switchNum = true
                  this.ifCzb=null
                  this.ifEtcsd=null
                  this.ifWjy=null
                  this.ifZyw=true
                  this.ShieldingButton(this.switchNum, this.ifCzb, this.ifEtcsd, this.ifWjy, this.ifZyw)
                }
                this.$message({
                    message: '修改成功',
                    type: 'success'
                });
         }else{
                if(this.switchNum == true){
                  this.switchNum = false
                  this.ifCzb=true
                  this.ifEtcsd=null
                  this.ifWjy=null
                  this.ifZyw=null
                  this.ShieldingButton(this.switchNum, this.ifCzb, this.ifEtcsd, this.ifWjy, this.ifZyw)
                 
                }else{
                  this.switchNum = true
                  this.ifCzb=true
                  this.ifEtcsd=null
                  this.ifWjy=null
                  this.ifZyw=null
                  this.ShieldingButton(this.switchNum, this.ifCzb, this.ifEtcsd, this.ifWjy, this.ifZyw)
                }
                this.$message({
                      message: '修改成功',
                      type: 'success'
                });
         }
      },

      //重置
      resetBtn(){
        var self=this
    	  self.reload() 
      },
      
    	oilstationList(name, gasStationId, type, way, brandType,oilNo, size, page,  provinceId, cityId, districtId, ifCzbStatus, ifEtcsdStatus, ifWjyStatus, ifZywStatus){
	      return new Promise((resolve, reject) => {
       	  this.loading = true;
		        getGasStationList(name, gasStationId, type, way, brandType, oilNo, size, page, provinceId, cityId, districtId, ifCzbStatus, ifEtcsdStatus, ifWjyStatus, ifZywStatus).then(res => {
                  this.loading = false;
			            if(res.code === 1){
                        this.tableData = res.content
                        console.log(this.tableData)
		            	      this.tableDataSec=this.tableData.filter(item => item.czbStationId!==null)
                        this.tableDataThi=this.tableData.filter(item => item.etcsdStationId!==null)
                        this.tableDataFou=this.tableData.filter(item => item.wjyStationId!==null)
                        this.tableDataFiv=this.tableData.filter(item => item.zywStationId!==null)
                          // console.log(this.tableDataSec)
                          // console.log(this.tableDataThi)
                          // console.log(this.tableDataFou)
                          // console.log(this.tableDataFiv)
                        this.pagination.total = res.totalElements
				                this.pagination.size = res.totalPages	 
			            }
			            resolve(res)  
		         }).catch(error => {
		         	     this.loading = false;
		               reject(error)
		         })
		      })
    	},
      //全部屏蔽和显示

      ShieldingButton(value, ifCzb, ifEtcsd, ifWjy, ifZyw){
	      return new Promise((resolve, reject) => {
       	  this.loading = true;
		        getGasStationBtn(value, ifCzb, ifEtcsd, ifWjy, ifZyw).then(res => {
                  this.loading = false;
			            if(res.code === 1){
                        this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize, this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus, this.ifEtcsdStatus, this.ifWjyStatus, this.ifZywStatus)
			            }
			            resolve(res)  
		         }).catch(error => {
		         	     this.loading = false;
		               reject(error)
		         })
		      })
    	},

      editStation(event, value){
          console.log(value.gasStationId)
          this.$router.push({path:'/oilCard-manage/oilStationDetail',query:{id:value.gasStationId}}) 
      },
      
        chooseoilstation(value){
        	console.log(value)
        	this.oilstationTypeID=value
        },

        chooseoilWay(value){
        	console.log(value)
        	this.oilWayTypeID=value
        },

        chooseoilbrand(value){
        	console.log(value)
        	this.oilbrandTypeID=value
        },
        //勾选数据行的 Checkbox 时触发的事件
        rowClassName ({ row, rowIndex }) {
          if (this.selectRow.includes(rowIndex)) {
            return 'slecleRowColor ';
          }
        },

        handleSelectionChange(value){
        	console.log(value)
          this.selectData = value
          let end = value[value.length-1]
          let gasID = end.gasStationId
          // console.log(end.gasStationId);//jQuery
          // this.gasStation(gasID)
        },

        changeStatus(value){
          console.log(value.ifShow)
          this.gasStation(value.gasStationId, value.ifShow)
          this.selectData.push(value)
          console.log(this.selectData)
        
        },

      gasStation(valueid,valueStatus){
	      return new Promise((resolve, reject) => {
       	    this.loading = true;
		        showGasStation(valueid, valueStatus).then(res => {
                    this.loading = false;
                    console.log(res)
                    if(res.code === 1){
                        this.$message({
                          message: '修改成功',
                          type: 'success'
                        });
                    }
			            resolve(res)  
		         }).catch(error => {
		         	     this.loading = false;
		               reject(error)
		         })
		      })
    	},


      pageSizeNum(value){
        console.log(value)
        this.pagination.pageSize=value
        this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize, this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus, this.ifEtcsdStatus, this.ifWjyStatus, this.ifZywStatus)
     },

     currentPageNum(event, value){
        this.pagination.currPage=event
        console.log(this.pagination.currPage)
        this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize, this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus, this.ifEtcsdStatus, this.ifWjyStatus, this.ifZywStatus)
     }
    },

    watch: {
    //监听多选的选择状态、数据
      selectData (data) {
        console.log(data)
        this.selectRow = []
        if (data.length > 0) {
          data.forEach((item, index) => {
              this.selectRow.push(this.tableData.indexOf(item))
              this.selectRow.push(this.tableDataSec.indexOf(item))
              this.selectRow.push(this.tableDataThi.indexOf(item))
              this.selectRow.push(this.tableDataFou.indexOf(item))
              this.selectRow.push(this.tableDataFiv.indexOf(item))
          })
        }
         console.log(this.selectRow)
      },
      cardType(){
        console.log(this.cardType)
        if(this.cardType=='全部'){
          this.ifCzbStatus= null
          this.ifEtcsdStatus= null
          this.ifWjyStatus= null 
          this.ifZywStatus= null 
          this.selectRow=[]
          this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize,this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus,this.ifEtcsdStatus,this.ifWjyStatus,this.ifZywStatus)
       
       }else if(this.cardType=='山东高速'){
         this.selectRow=[]
          this.ifCzbStatus= null
          this.ifEtcsdStatus= true
          this.ifWjyStatus= null 
          this.ifZywStatus= null 
          this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize,this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus,this.ifEtcsdStatus,this.ifWjyStatus,this.ifZywStatus)
        }else if(this.cardType=='万金油'){
           this.selectRow=[]
          this.ifCzbStatus= null
          this.ifEtcsdStatus= null
          this.ifWjyStatus= true
          this.ifZywStatus= null 
          this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize,this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus,this.ifEtcsdStatus,this.ifWjyStatus,this.ifZywStatus)
        }else if(this.cardType=='老吕加油'){
          this.selectRow=[]
          this.ifCzbStatus= null
          this.ifEtcsdStatus= null
          this.ifWjyStatus= null
          this.ifZywStatus= true 
          this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize,this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus,this.ifEtcsdStatus,this.ifWjyStatus,this.ifZywStatus)
        }else{
          this.selectRow=[]
          this.ifCzbStatus= true
          this.ifEtcsdStatus= null
          this.ifWjyStatus= null
          this.ifZywStatus= null
          this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize,this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus,this.ifEtcsdStatus,this.ifWjyStatus,this.ifZywStatus)
        }
      }
    },
    
    mounted(){
        this.oilstationList(this.name, this.gasStationId, this.oilstationTypeID, this.oilWayTypeID, this.oilbrandTypeID, this.oilNo, this.pagination.pageSize, this.pagination.currPage, this.provinceId,  this.cityId,  this.districtId, this.ifCzbStatus, this.ifEtcsdStatus, this.ifWjyStatus, this.ifZywStatus)
    }
  }
</script>
<style scoped >
.body_kuang{
	padding: 20px 20px 0px 20px;
	border: 1px solid #C8C8C8;
	margin-bottom: 30px;
}
.btn_po{
	margin-bottom: 10px;
}
.el-form-item{
   margin:0 45px 10px 0;
}

.body >>> .distpicker-address-wrapper{
    padding: 0rem .75rem;
    height:30px;
}
.body >>>select{
    padding: 0rem .75rem;
    height:30px;
}

.body >>> .distpicker-address-wrapper select {
    padding: 0rem .75rem;
    height: 30px;
}
</style>