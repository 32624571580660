<template>
	<div class="app-main">
	  <div class="body">                    
		    <el-form inline class="my-form">
		      <el-form-item label="交易单号：">
		           <el-input size="small" placeholder=""></el-input>
		      </el-form-item>
   	          <el-form-item label="提现时间：">
		           <time-picker @timeValue="getValue(arguments)"></time-picker>
		      </el-form-item>
		      
		      <el-form-item>
			        <el-button size="small" type="primary">查询</el-button>
			        <el-button size="small">重置</el-button>
			        <el-button size="small">导出</el-button>
		      </el-form-item>
		    </el-form>
		    
		    <div class="body_list">
		    	<div class="profitMoney">
		    		 <span>分润总金额</span>
		    		 <p>¥1000</p>
		    	</div>
		    	
		    	<div class="profitMoney">
		    		 <span>待发放单数</span>
		    		 <p>¥1000</p>
		    	</div>
		    	
		    	<div class="profitMoney">
		    		 <span>待发放总金额</span>
		    		 <p>¥1000</p>
		    	</div>
		    	<div class="profitMoney">
		    		 <span>已发放总金额</span>
		    		 <p>¥1000</p>
		    	</div>
		    </div>
       </div>
       <div class="body m-t20">
       	    <div class="btn_po">
  		  		<el-radio-group v-model="cardType">
			        <el-radio-button label="全部"></el-radio-button>
			        <el-radio-button label="待发放"></el-radio-button>
		            <el-radio-button label="已发放"></el-radio-button>
				</el-radio-group>
				<div class="showMoney">
					<span>待发放单数:{{}}</span>
					<span>待发放总金额:{{}}</span>
				</div>
			</div>
       	    <page-table :data="tableData" :columns="columns" :pagination="pagination" v-if="cardType=='全部'" :loading="loading">
		      <el-table-column label="操作" align="center">
		        <template slot-scope="scope">
	                <el-tag @click.stop="" class="btnlist">编辑</el-tag>
		        </template>
		      </el-table-column>
		    </page-table>
		    
		    <page-table :data="tableData" :columns="columns" :pagination="pagination" v-if="cardType=='待发放'" :loading="loading">
		      <el-table-column label="操作" align="center">
		        <template slot-scope="scope">
	                <el-tag @click.stop="" class="btnlist">编辑</el-tag>
		        </template>
		      </el-table-column>
		    </page-table>
		    
		    <page-table :data="tableData" :columns="columns" :pagination="pagination" v-if="cardType=='已发放'" :loading="loading">
		      <el-table-column label="操作" align="center">
		        <template slot-scope="scope">
	                <el-tag @click.stop="" class="btnlist">编辑</el-tag>
		        </template>
		      </el-table-column>
		    </page-table>
       </div>
       
	   <my-dialog :is-show="isShowAboutDialog"  :show-title="showTitle"  @on-close="closeDialog('isShowAboutDialog')">
            <span>55555</span>
      </my-dialog>
      
  </div>
</template>
<script>
  import { getGasOrderList, getGasOrder } from "@/api/order";
  import pageTable from '../../components/pageTable'
  import Dialog from '../../components/dialog'
  import VSelect from '../../components/select'
  import timePicker from '../../components/timePicker'
  export default {
    name: 'addOildetail',
    data() {
      return {
      	cardType: '全部',
        tableData: [],
        columns: [
          {
            name: '结算单号',
            value: 'updateTime'
          },
          {
            name: '充值订单',
            value: 'no'
          },
          {
            name: '充值金额（元）',
            value: 'price'
          },
          {
            name: '一级分润',
            value: 'provider'
          },
          {
            name: '二级分润',
            value: 'user'
          },
          {
            name: '三级分润',
            value: 'paystate'
          },
          {
            name: '结算状态',
            value: 'gasStation'
          }
        ],
        loading:false,
        pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage: 0,
          size: 0
        },
        isShowAboutDialog:true,
        showTitle:'加油详情'
      }
    },
    components: { pageTable,MyDialog:Dialog,VSelect,timePicker }, 
    created() {
      document.title = '加油订单'
      this.$store.dispatch('SetNavbar', [{ meta: { title: '管理中心' }, path: '/oilCard-manage/index' }, { meta: { title: '油卡管理' }, path: '/oilCard-manage/manage' }, { meta: { title: '加油订单' } }])
    },
    methods:{
    	getDetail(){
    	  this.isShowAboutDialog=true
    	  this.showTitle="加油详情"
    	},
    	closeDialog (attr) {
	       this[attr] = false
	    },
	    oilOrderList(){
      	 	    return new Promise((resolve, reject) => {
    	       	this.loading = true;
		        getGasOrderList().then(res => {
		             this.loading = false;
			            if(res.code === 1){
			            	this.tableData = res.content
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		         })
		      })
      }
    },
    mounted(){
//  	this.oilOrderList()
    }
  }
</script>
<style scoped lang="scss">
.el-form-item{
   margin:0 45px 10px 0;
}
.btn_po{
	margin: 0px 10px 10px 0;
	display: flex;
	.showMoney{
		display: flex;
		flex: 1;
		color:#808080;
		justify-content: flex-end;
        align-items: center;
	}
}
.body_list{
	display: flex;
	justify-content: center;
    align-items: center;
    padding-top: 20px;
	.profitMoney{
		flex: 1;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		span{
			font-size: 18px;
			color: #333333;
			font-weight: bold;
		}
		p{
			font-size: 26px;
			color: crimson;
		}
	}
}


</style>