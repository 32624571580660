<template>
  <div class="app-main">
  	 <div class="body">
				     <el-form inline class="my-form">
					    	<el-form-item label="提现时间：">
					        <time-picker @timeValue="getValue(arguments)"></time-picker>
					      </el-form-item>
				      <el-form-item label="提现单号：">
				        <el-input size="small" placeholder="" v-model="no"></el-input>
				      </el-form-item>
				      <el-form-item label="提现手机号：">
				        <el-input size="small" placeholder="" v-model="mobile"></el-input>
				      </el-form-item>
				      <el-form-item label="提现用户：">
				        <el-input size="small" placeholder="" v-model="realname"></el-input>
				      </el-form-item>
				      <el-form-item>
				            <el-button size="small" type="primary" @click.stop="findData">查询</el-button>
			            <el-button size="small" @click.stop="resetBtn">重置</el-button>
			            <el-button size="small">导出</el-button>
				      </el-form-item>
				    </el-form>
        </div>
        <div class="body m-t20">
			    <div class="btn_po">
	  		  		<el-radio-group v-model="cardType">
					          <el-radio-button label="全部"></el-radio-button>
					          <el-radio-button label="待审核"></el-radio-button>
				            <el-radio-button label="审核成功"></el-radio-button>
				            <el-radio-button label="审核失败"></el-radio-button>
					    </el-radio-group>
			  	</div>
			  	<page-table :data="tableData" :columns="columns" :pagination="pagination" v-if="cardType=='全部'" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
			      <el-table-column label="操作" align="center">
			        <template slot-scope="scope">
			             <el-tag
						          :type="scope.row.state === '2' ? 'primary' : 'success'" style="cursor: pointer;" @click.stop="widthDrawDetail(scope.$index, scope.row)"
						       >{{scope.row.state === 0 ? '审核' : '查看'}}</el-tag>
			        </template>
			      </el-table-column>
			    </page-table>
			  	<page-table :data="tableDataSec" :columns="columns1" :pagination="pagination" v-if="cardType=='待审核'" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		             <el-tag
						          :type="scope.row.state === '2' ? 'primary' : 'success'" style="cursor: pointer;" @click.stop="widthDrawDetail(scope.$index, scope.row)"
						          disable-transitions>{{scope.row.state === 0 ? '审核' : '查看'}}</el-tag>
		          </template>
		        </el-table-column>
		     </page-table>
		    <page-table :data="tableDataThi" :columns="columns2" :pagination="pagination" v-if="cardType=='审核成功'" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		             <el-tag
						          :type="scope.row.state === '2' ? 'primary' : 'success'" style="cursor: pointer;" @click.stop="widthDrawDetail(scope.$index, scope.row)"
						          disable-transitions>{{scope.row.state === 0 ? '审核' : '查看'}}</el-tag>
		          </template>
		        </el-table-column>
		     </page-table>
		    <page-table :data="tableDataFou" :columns="columns3" :pagination="pagination" v-if="cardType=='审核失败'" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		            <el-tag
						          :type="scope.row.state === '2' ? 'primary' : 'success'" style="cursor: pointer;" @click.stop="widthDrawDetail(scope.$index, scope.row)"
						          disable-transitions>{{scope.row.state === 0 ? '审核' : '查看'}}</el-tag>
		          </template>
		        </el-table-column>
		    </page-table>
	  	</div>

  	  <v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" :dialogFooter='dialogFooter'  @update:dialogVisible="dialogVisibles" @update:confirmdialogVisible="confrmdialogVisibles">
  	 		   <div class="dialog_content" v-show="oildetailStatus" v-for="item in this.widthDrawData" >
	            <v-step :itemData="widthDrawData"></v-step>
	            <div style="padding-top: 20px;">
            	      <p>提现金额：{{item.price}}元</p>
				    	   	  <p>手续费：{{item.servicePrice}}</p>
				    	   	  <p>申请时间：{{item.addTime}}</p>
				    	   	  <p>到账时间：{{item.finishTime}}</p>
				    	   	  <p>提现账户：{{item.user.realname}}</p>
				    	   	  <p>提现单号：{{item.no}}</p>
	            </div>
	     	  </div>
	     	  
	     	  <div v-show="oildetailAuditStatus">
	     	  	 <el-radio v-model="radiosh" label="1">通过</el-radio>
             <el-radio v-model="radiosh" label="2">拒绝</el-radio>
	     	  </div>
     </v-dialog>
  </div>
</template>
<script>
	import { getWithdrawList, getWithdraw, auditWithdraw } from "@/api/withdraw";
	import pageTable from '../../components/pageTable'
	import timePicker from '../../components/timePicker'
  import VDialog from '../../components/dialog'
	import VStep from '../../components/step'
  export default {
    name: 'withdrawDetail',
    inject:['reload'],
    data() {
      return {
       	cardType: '全部',
        tableData: [],
        widthDrawData:[],
        tableDataSec: [],
        tableDataThi: [],
        tableDataFou: [],
        columns: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '提现单号',
            value: 'no'
          },
          {
            name: '提现卡号',
            value: 'widthDrawAccount'
          },
          // {
          //   name: '名称',
          //   value: 'widthDrawName'
          // },

          {
            name: '提现渠道',
            value: 'wayChannel'
          },
          
          {
            name: '提现用户',
            value: 'user'
          },
          {
            name: '手机号',
            value: 'widthdrawPhone'
          },
          {
            name: '金额（元）',
            value: 'price'
          },
          {
            name: '状态',
            value: 'withdrawstate'
          },
          {
            name: '失败原因',
            value: 'failureMsg'
          }
        ],
        columns1: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '提现单号',
            value: 'no'
          },
          {
            name: '提现卡号',
            value: ''
          },
          {
            name: '提现用户',
            value: 'user'
          },
          {
            name: '手机号',
            value: 'widthdrawPhone'
          },
          {
            name: '金额（元）',
            value: 'price'
          },
          {
            name: '状态',
            value: 'withdrawstate'
          },
          {
            name: '失败原因',
            value: 'failureMsg'
          }
        ],
        columns2: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '提现单号',
            value: 'no'
          },
          {
            name: '提现卡号',
            value: ''
          },
          {
            name: '提现用户',
            value: 'user'
          },
          {
            name: '手机号',
            value: 'widthdrawPhone'
          },
          {
             name: '金额（元）',
            value: 'price'
          },
          {
            name: '状态',
            value: 'withdrawstate'
          },
          {
            name: '失败原因',
            value: 'failureMsg'
          }
        ],
        columns3: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '提现单号',
            value: 'no'
          },
          {
            name: '提现卡号',
            value: ''
          },
          {
            name: '提现用户',
            value: 'user'
          },
          {
            name: '手机号',
            value: 'widthdrawPhone'
          },
          {
            name: '金额（元）',
            value: 'price'
          },
          {
            name: '状态',
            value: 'withdrawstate'
          },
          {
            name: '失败原因',
            value: 'failureMsg'
          }
        ],
        columns4: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '提现单号',
            value: 'no'
          },
          {
            name: '提现卡号',
            value: ''
          },
          {
            name: '提现用户',
            value: 'user'
          },
          {
            name: '手机号',
            value: 'widthdrawPhone'
          },
          {
            name: '金额（元）',
            value: 'price'
          },
          {
            name: '状态',
            value: 'withdrawstate'
          },
          {
            name: '失败原因',
            value: 'failureMsg'
          }
        ],




        loading:false,
        pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage: 0,
          size: 0
        },
        dialogVisible:false,
        oildetailStatus:false,
        oildetailAuditStatus:false,
        dialogInfo:'',
        dialogFooter:false,
        
        startAddTime:'', 
        endAddTime:'', 
        no:'', 
        mobile:'', 
        realname:'',
        checkStatusID:'',
        radiosh:'1',
        checkStatus:'',
        withdrawId:0
      }
    },
    components: { pageTable, timePicker, VDialog, VStep},
    created() {
      document.title = '提现管理'
      this.$store.dispatch('SetNavbar', [{ meta: { title: '管理中心' }, path: '/oilCard-manage/index' }, { meta: { title: '油卡管理' }, path: '/oilCard-manage/manage' }, { meta: { title: '流水记录' }, path: '/oilCard-manage/flow-detail' }, { meta: { title: '提现管理' } }])
    },
    methods:{
    	getValue(data){
//  		 console.log(data)
        this.startAddTime=data[0]
        this.endAddTime=data[1]
    	},
     	findData(){
    	  this.withdrawList(this.startAddTime, this.endAddTime, this.no, this.mobile, this.realname, this.pagination.pageSize,this.pagination.currPage)
    	},
    	resetBtn(){
           var self=this
    	     self.reload () 
    	},
	    withdrawList(startAddTime, endAddTime, no, mobile, realname, size, page){
 	        return new Promise((resolve, reject) => {
       	  this.loading = true;
		        getWithdrawList(startAddTime, endAddTime, no, mobile, realname, size, page).then(res => {
			            if(res.code === 1){
			            	 this.loading = false;
                     this.tableData = res.content
                     console.log(this.tableData)
			            	 this.tableDataSec=this.tableData.filter(item => item.state===2)
							       this.tableDataThi=this.tableData.filter(item => item.state===1)
							       this.tableDataFou=this.tableData.filter(item => item.state===-1)
							       this.pagination.total = res.totalElements
				             this.pagination.size = res.totalPages	
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		          })
		      })
      },

      pageSizeNum(value){
      	  console.log(value)
      	  this.pagination.pageSize=value
          this.withdrawList(this.startAddTime, this.endAddTime, this.no, this.mobile, this.realname, this.pagination.pageSize,this.pagination.currPage)
      },
      currentPageNum(event, value){
      	  console.log(event)
      	  this.pagination.currPage=event
      	  this.withdrawList(this.startAddTime, this.endAddTime, this.no, this.mobile, this.realname, this.pagination.pageSize,this.pagination.currPage)
      },

     auditWithdrawList(id, result){
   	        return new Promise((resolve, reject) => {
       	  this.loading = true;
		        auditWithdraw(id, result).then(res => {
			            if(res.code === 1){
			            	 console.log(res)
			            	 this.loading = false;
			               this.$message.success("提现审核成功")
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		          })
		      })
      },

      widthDrawDetail(event, value){
      	console.log(value)
      	if(value.state=='2'){
      		this.widthDrawData=[value]
         	this.dialogVisible=true
          this.oildetailStatus=true
          this.dialogInfo='消费提现-到微信零钱'
      		
      	}else{
        	this.dialogInfo="审核信息"
        	this.dialogVisible=true
          this.dialogFooter=true
          this.oildetailStatus=false
          this.oildetailAuditStatus=true
          this.withdrawId=value.withdrawId
          console.log(this.withdrawId)
      	}
      },
      //子组件传 过来的 数据
		  dialogVisibles(v){
		      this.dialogVisible = v
		  },
		  confrmdialogVisibles(v){
		     	if(this.oildetailAuditStatus==true){
	
		     		 if(this.radiosh=="1"){
		     		 	
		     		 	this.checkStatus="success"
		     		 	
		     		 }else{
		     		 	
		     		 		this.checkStatus="failure"
		     		 }
		     		
		     		 this.auditWithdrawList(this.withdrawId, this.checkStatus)
		     		 this.dialogVisible = v
		     	}
		  }
    },
    mounted(){
       this.withdrawList(this.startAddTime, this.endAddTime, this.no, this.mobile, this.realname, this.pagination.pageSize,this.pagination.currPage)
    }
  }
</script>
<style scoped lang="scss">
.btn_po{
	margin: 0px 10px 10px 0;
}
.el-form-item{
   margin:0 45px 0px 0;
}
</style>