<template>
  <div class="app-main">
     <div class="body">
			  	<div class="body_kuang">
				    <el-form inline class="my-form">
			      	<el-form-item label="申请时间：">
				         <time-picker @timeValue="getValue(arguments)"></time-picker>
				      </el-form-item>
			         <el-form-item label="申请人手机号：">
			          <el-input size="small" placeholder="" v-model="applimobile"></el-input>
			        </el-form-item>
				      <el-form-item label="状态：">
				             <v-select :selectData="invoiceStatus" :selectID="selectID" @choonseGrageEvent="chooseBrand"></v-select> 
				      </el-form-item>
				       <el-form-item label="发票抬头：">
			          <el-input size="small" placeholder="" v-model="invoiceTitle"></el-input>
			        </el-form-item>
			        <el-form-item label="纳税人识别号：">
			          <el-input size="small" placeholder="" v-model="TaxpayerNum"></el-input>
			        </el-form-item>
						  <el-form-item>
				        <el-button size="small" type="primary" @click.stop="findData">查询</el-button>
				        <el-button size="small" @click.stop="resetBtn">重置</el-button>
				        <el-button size="small"  disabled>导出Excel</el-button>
				      </el-form-item>
				    </el-form>
			  	</div>
		</div>
		
    <div class="body m-t20">
	    	<div class="btn_po">
	  		  		<el-radio-group v-model="cardType">
				        <el-radio-button label="全部"></el-radio-button>
				        <el-radio-button label="处理中"></el-radio-button>
				        <el-radio-button label="开票成功"></el-radio-button>
			          <el-radio-button label="开票失败"></el-radio-button>
					    </el-radio-group>
				</div>
        <page-table :data="tableData" :columns="columns" :pagination="pagination" v-if="cardType=='全部'" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		                <el-tag @click.stop="goDetail(scope.$index, scope.row)" class="btnlist">详情</el-tag>
		                <el-tag @click.stop="postWay(scope.$index, scope.row)" class="btnlist" v-if="scope.row.status == 2">邮寄</el-tag>
	                  <el-tag @click.stop="checkAudit(scope.$index, scope.row)" class="btnlist" v-if="scope.row.status == 3">审核</el-tag>
		          </template>
		        </el-table-column>
		      </page-table>
		      <page-table :data="tableDataSec" :columns="columns1" :pagination="pagination" v-if="cardType=='处理中'" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		                <el-tag @click.stop="goDetail(scope.$index, scope.row)" class="btnlist">详情</el-tag>
		                <el-tag @click.stop="postWay(scope.$index, scope.row)" class="btnlist" v-if="scope.row.status == 2">邮寄</el-tag>
	                  <el-tag @click.stop="checkAudit(scope.$index, scope.row)" class="btnlist" v-if="scope.row.status == 3">审核</el-tag>
		          </template>
		        </el-table-column>
		      </page-table>
		      <page-table :data="tableDataThi" :columns="columns2" :pagination="pagination" v-if="cardType=='开票成功'" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		                <el-tag @click.stop="goDetail(scope.$index, scope.row)" class="btnlist">详情</el-tag>
		                <el-tag @click.stop="postWay(scope.$index, scope.row)" class="btnlist" v-if="scope.row.status == 2">邮寄</el-tag>
	                  <el-tag @click.stop="checkAudit(scope.$index, scope.row)" class="btnlist" v-if="scope.row.status == 3">审核</el-tag>
		          </template>
		        </el-table-column>
		      </page-table>
		      <page-table :data="tableDataFou" :columns="columns3" :pagination="pagination" v-if="cardType=='开票失败'" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
		        <el-table-column label="操作" align="center">
		          <template slot-scope="scope">
		                <el-tag @click.stop="goDetail(scope.$index, scope.row)" class="btnlist">详情</el-tag>
		                <el-tag @click.stop="postWay(scope.$index, scope.row)" class="btnlist" v-if="scope.row.status == 2">邮寄</el-tag>
	                  <el-tag @click.stop="checkAudit(scope.$index, scope.row)" class="btnlist" v-if="scope.row.status == 3">审核</el-tag>
		          </template>
		        </el-table-column>
		      </page-table>
    </div>

	  <v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" @update:dialogVisible="dialogVisibles" @update:confirmdialogVisible="confrmdialogVisibles">
	     	   <div class="dialog_content" v-show="modifyStatus">	     	   	 
	     	   	  <p class="dialog_list">
	     	   	  	  <label>邮寄方式：</label>
	     	     		 	<el-radio v-model="radio" label="1">普通快递</el-radio>
                  <el-radio v-model="radio" label="2">无需邮寄</el-radio>
	     	   	  </p>
	     	   	  <p class="dialog_list" v-if="radio=='1'">
	     	   	  	<label>快递单号：</label>
	     	   	  	<el-input placeholder="请输入" v-model="postNo" clearable></el-input>
	     	   	  </p>
	     	   	  <p class="dialog_list" v-if="radio=='1'">
	     	   	  	<label>快递公司：</label>
	     	   	  	<el-input placeholder="请输入" v-model="postCompany" clearable></el-input>
	     	   	  </p>
	     	   </div>
	     	   
	     	   <div class="rechargeBalance" v-show="rechargeStatus">
	     	   	  <p class="invoiceTitle">
	     	   	  	    <el-radio v-model="auditStatus" label="1">审核通过</el-radio>
                    <el-radio v-model="auditStatus" label="2">审核驳回</el-radio>
	     	   	  </p>
	     		    <p class="dialog_list" v-if="auditStatus=='2'">
	     	     		 <label >驳回原因：</label>
	     	     		 <el-input type="textarea" v-model="desc"></el-input>
	     	   	  </p>
	     	   </div>
	  </v-dialog>
  </div>
</template>
<script>
	import { getInvoiceBillList, getGasBill, getGasBillPost, getGasBillaudit } from "@/api/invoice";
  import pageTable from '../../components/pageTable'
  import VDialog from '../../components/dialog'
  import timePicker from '../../components/timePicker'
  import VSelect from '../../components/select'
  export default {
    name: 'manage',
    inject:['reload'],
    data() {
      return {
        cardType: '全部',
        tableData: [],     	
        tableDataSec:[],
			  tableDataThi:[],
			  tableDataFou:[],
        invoiceStatus: [{
          value: 1,
          label: '已开发票（邮寄）'
        }, {
          value: 2,
          label: '已开发票（未邮寄）'
        }, {
          value: 3,
          label: '未开发票'
        }, {
          value: 4,
          label: '开票失败'
        }, {
          value: 5,
          label: '已开发票（无需邮寄）'
        }],
        columns: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '单位名称',
            value: 'invoiceLookUp'
          },
          {
            name: '纳税人识别号',
            value: 'identifier'
          },  
          {
            name: '手机号码',
            value: 'phone'
          }, 
          {
            name: '开票金额（元）',
            value: 'amount'
          },
          {
            name: '开票状态',
            value: 'allInvoiceStatus'
          }
        ],
        columns1: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '单位名称',
            value: 'invoiceLookUp'
          },
          {
            name: '纳税人识别号',
            value: 'identifier'
          },  
          {
            name: '手机号码',
            value: 'phone'
          }, 
          {
            name: '开票金额（元）',
            value: 'amount'
          },
          {
            name: '开票状态',
            value: 'allInvoiceStatus'
          }
        ],
        columns2: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '单位名称',
            value: 'invoiceLookUp'
          },
          {
            name: '纳税人识别号',
            value: 'identifier'
          },  
          {
            name: '手机号码',
            value: 'phone'
          }, 
          {
            name: '开票金额（元）',
            value: 'amount'
          },
          {
            name: '开票状态',
            value: 'allInvoiceStatus'
          }
        ],
        columns3: [
          {
            name: '时间',
            value: 'addTime'
          },
          {
            name: '单位名称',
            value: 'invoiceLookUp'
          },
          {
            name: '纳税人识别号',
            value: 'identifier'
          },  
          {
            name: '手机号码',
            value: 'phone'
          }, 
          {
            name: '开票金额（元）',
            value: 'amount'
          },
          {
            name: '开票状态',
            value: 'allInvoiceStatus'
          }
        ],
        loading:false,
        pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage: 0,
          size: 0
        },
        radio:"1",
        auditStatus:'2',
        postNo:'',
        postCompany:'',
        desc:'',
        dialogVisible: false,
	      dialogInfo:'',
        modifyStatus:false,
	      rechargeStatus:true,
	      invoiceID:0,
	      
	      selectID:'',
	      applimobile:'',
	      invoiceTitle:'',
	      TaxpayerNum:'',
	      timeValueFir:'',
	      timeValueSec:'',
	      amount:0
      }
    },
    methods: {
    	getValue(data){
 		    this.timeValueFir=data[0]
	      this.timeValueSec=data[1]
    	},
      //查询
    	findData(){
    	  //调取查询接口 
	
    	},
    	resetBtn(){
    		var self=this
    	  self.reload () 
      },
    	// exportBtn(){
    	// 	console.log(8)    		
    	// },
    	goDetail(event, value){
         console.log(value.user.userId)
    		 this.$router.push({path:'/oilCard-manage/invoicemanangeDetail',query:{id:value.invoiceId, inVoiceUserID:value.user.userId}}) 
    	},
      oilInvoiceList(size, page){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getInvoiceBillList(size, page).then(res => {
			              this.loading = false;
				            if(res.code === 1){
				            	let firArr=[]
				            	let secArr=[]
				            	let thiArr=[]
                      this.tableData = res.content
                     // console.log(this.tableData)
							        this.tableDataSec=this.tableData.filter(item => item.status == 3)
										  this.tableDataFou=this.tableData.filter(item => item.status == 4)
										  thiArr=this.tableData.filter(item => item.status == 1)
										  firArr=this.tableData.filter(item => item.status == 2)
										  secArr=this.tableData.filter(item => item.status == 5)
										  thiArr.push(...firArr);
											thiArr.push(...secArr);
											this.tableDataThi=thiArr
											
			                this.pagination.total = res.totalElements
				              this.pagination.size = res.totalPages	
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },
      
      getGasBillPostList(id, deliveryCompany, deliveryNo, deliveryStatus){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasBillPost(id, deliveryCompany, deliveryNo, deliveryStatus).then(res => {
			              this.loading = false;
				            if(res.code === 1){
									        this.$message({
									          message: '邮寄成功',
									          type: 'success'
									        })
				            	 	  this.oilInvoiceList()
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },
      
      getGasBillauditList(id, failReason, auditStatus, amount){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasBillaudit(id, failReason, auditStatus, amount).then(res => {
			              this.loading = false;
				            if(res.code === 1){
                          console.log(res)
									        this.$message({
									          message: '审核成功',
									          type: 'success'
									        });
				            	 	this.oilInvoiceList()
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },

      postWay(event, value){
      	this.invoiceID=value.invoiceId
      	this.dialogVisible=true
        this.modifyStatus=true
	      this.rechargeStatus=false
        this.dialogInfo='邮寄'
      },
      checkAudit(event, value){
      	this.invoiceID=value.invoiceId
      	this.amount=value.amount
      	this.dialogVisible=true
        this.modifyStatus=false
	      this.rechargeStatus=true
      	this.dialogInfo='审核'
      },
 
      pageSizeNum(value){
      	  console.log(value)
      	  this.pagination.pageSize=value
      	  this.oilInvoiceList(this.pagination.pageSize,this.pagination.currPage)
      },
      currentPageNum(event, value){
      	  console.log(event)
      	  this.pagination.currPage=event
      	  this.oilInvoiceList(this.pagination.pageSize,this.pagination.currPage)
      },

      //子组件传 过来的 数据
	    dialogVisibles(v){
	      this.dialogVisible = v
	    },
	    confrmdialogVisibles(v){
	    	 if(this.modifyStatus==true){
	       	   	this.getGasBillPostList(this.invoiceID, this.postCompany, this.postNo, this.radio)
	    	 	    this.dialogVisible = v
	    	 	  
	    	 }else if(this.rechargeStatus==true){
		 		   	 this.getGasBillauditList(this.invoiceID, this.desc, this.auditStatus, this.amount)
		 	       this.dialogVisible = v
	    	 }
	   },
	   chooseBrand(value){
        console.log(value)
     }
    },
    components: { pageTable, timePicker, VDialog, VSelect},
    filters: {
      money(n) {
        let num = n.toFixed(2)
        let numInt = num.split('.')[0]
        let numFloat = num.split('.')[1]
        let numIntArr = numInt.split('')
        let numIntArrReverse = numIntArr.reverse()
        let arr = []
        numIntArrReverse.map((item, index) => {
          arr.push(item)
          if ((index + 1) % 3 == 0) {
            arr.push(',')
          }
        })
        return arr.reverse().join('') + '.' + numFloat
      }
    },
    mounted(){
       this.oilInvoiceList(this.pagination.pageSize,this.pagination.currPage)
    }
  }
</script>
<style lang="scss" scoped>
  .head {
    background: #fff;
    display: flex;
    padding-left: 20px;
    align-items: center;
    height: 100px;
    margin-bottom:20px;
    .money {
      vertical-align: baseline;
      margin: 0 20px;
      span {
        font-size: 20px;
      }
    }
  }
  .el-form-item {
    margin:15px 45px 0 0;
  }
  .all-money {
    margin: 5px 0;
    font-size: 14px;
    color: #817979;
    text-align: right;
  }
  .allMoneyText{
  	 font-size: 16px;
  	 text-align: right;
  }
  .btn_po{
  	margin: 0 10px 10px 0;
  }
  .btns{
    display: flex;
  	justify-content: flex-end;
  }
  .dialog_list{
  	display: flex;
  	padding-bottom: 10px;
  	label{
  		width: 20%;
  		line-height: 35px;
  	}
  }
  .invoiceTitle{
	  padding-bottom: 20px;
  }
</style>