import request from "@/utils/request";
/**
 * 获取加油卡列表
 */

export function getGasCardList(size, page) {
  return request.get("/oilCard/api/gas-cards", { 
	  	params: {
		  	size:size,
		  	page:page
		}
  	});
}

/**
 * 获取加油卡详情信息
 */

export function getGasCard(id, size, page) {
  return request.get("/oilCard/api/gas-card/"+id, {
  	
  		params: {
		  	size:size,
		  	page:page
		}
  	
  });
}

/**
   修改金额
 */
export function changeBalance(id, remark, amount,ifInvoice) {
  return request.get("/oilCard/api/gas-card/"+id+"/changeBalance", {
  	 params: {
  	 	remark:remark,
		amount:amount,
		ifInvoice:ifInvoice
	 }
  })
}





/**
  充值
 */
export function rechargeBalance(nonumber, typeValue, DoublebalanceMoney, rechargeUserId, rechargeGasCardId) {
  return request.put("/oilCard/api/gas-card/rechange?no="+nonumber+"&type="+typeValue+"&balance="+DoublebalanceMoney+"&userId="+rechargeUserId+"&gasCardId="+rechargeGasCardId, {});
}
/**
   冻结
 */
export function FrozenCard(no,state) {
   return request.get("/oilCard/api/freeze-card/"+ no +"/" + state, {});
}

/**
 查询
 */

export function findData(no, realname, mobile, type) {
	return request({
	    url: "/oilCard/api/gas-cards-condition",
	    method: "post",
	    data: {
	        no: no,
		    realname: realname,
		    mobile: mobile,
		    type: type
	    }
	})
}

