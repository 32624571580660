<template>
	<div class="app-main">
	  <div class="body">
	  	<div class="kuang">
		       <p>
			        <img :src="logoImg" class="imgLogo">
			        <span class="getInfo">编辑LOGO</span>
		       </p>
		       <p>
		            <label>油站ID</label>	       	
			       	<span>{{tableDataDetail.gasStationId}}</span>
		       </p>
		       <!-- <p>
			       	<label>车主邦ID</label>
			       	<span>{{tableDataDetail.czbStationId}}</span>
		       </p>
		       <p>
			       	<label>山东高速ID</label>
			       	<span>{{tableDataDetail.etcsdStationId}}</span>
		       </p>
					 <p>
			       	<label>老吕加油ID</label>
			       	<span>{{tableDataDetail.etcsdStationId}}</span>
		       </p> -->
		       <p>
			       	<label>包含油品</label>
			       	<span>{{tableDataDetail.oilNos}}号</span>
		       </p>
		       <p>
			       	<label>最低价格</label>
			       	<span v-if="tableDataDetail.minPrice==null">无</span>
							<span v-if="tableDataDetail.minPrice!==null">{{tableDataDetail.minPrice}}</span>
		       </p>       
		       <p>
		       	    <label>油站名称</label>
		       	    <span>
       	    		    <el-input
						  placeholder="请输入内容"
						  v-model="oilname"
						  clearable>
						</el-input>
		       	    </span>
		       </p>
		       <p>
	            	<label>油站地区</label>
	            	<span>
	             	    <v-distpicker @selected="onSelected"></v-distpicker>
	            	</span>
		       </p>
		       <p>
		       	 <label>详细地址</label>
		       	 <span>
		       	 	<textarea rows="4" cols="25" v-model="address" class="addressTextarea"></textarea>
		       	 </span>
		         <span class="getInfo">地图定位</span>
		       </p>
		       <p>
		       	 <label>道路类型</label>
		       	 <span>
                    <v-select :selectData="waysType"  :selectID="selectID" @choonseGrageEvent="chooseWays"></v-select> 
		       	 </span>
		       </p>
		       
		       <p>
		       	 <label>油站类型</label>
		       	 <span>
       	 	        <v-select :selectData="oilStstionType" :selectID="selectID" @choonseGrageEvent="chooseOilstation"></v-select> 
		       	 </span>
		       </p>
		       
		       <p>
		       	 <label>品牌类型</label>
		       	 <span>
       	 	        <v-select :selectData="brandType" :selectID="selectID" @choonseGrageEvent="chooseBrand"></v-select> 
		       	 </span>
		       </p>
		       <p style="margin:10px 80px">
		       	 <el-button type="primary" @click.stop="saveBtn">保存</el-button>
		       </p>
	      </div>
      </div>
  </div>
</template>
<script>
  import { getGasStation, updateGasStation } from "@/api/station";
  import VDistpicker from 'v-distpicker'
  import VSelect from '../../components/select'
  export default {
    name: 'oilStationDetail',
    data() {
      return {
      	tableDataDetail:{},
        waysType: [{
          value: 1,
          label: '高速'
        }, {
          value: 2,
          label: '国道',
       
        }, {
          value: 3,
          label: '其他'
        }],
        
        oilStstionType: [{
          value: 1,
          label: '中石油'
        }, {
          value: 2,
          label: '中石化',
    
        }, {
          value: 3,
          label: '壳牌'
        }, {
          value: 4,
          label: '其他'
        }],
        
        brandType: [{
          value: 1,
          label: '国营央企'
        }, {
          value: 2,
          label: '民营私企',
        }, {
          value: 3,
          label: '外企/合资'
        }],
        logoImg:'',
        oilname:'',
        address:'',
        roadtype:'',
        oiltype:'',
        brandtype:'',
        gasStationId:0,
        selectID:1,
        waysID:1,
        oilStationID:1,
        brandID:1
 
      }
    },   
    components: { VDistpicker,VSelect },
    created() {
      document.title = '油站管理'
      //this.$store.dispatch('SetNavbar', [{ meta: { title: '管理中心' }, path: '/oilCard-manage/index' }, { meta: { title: '油卡管理' }, path: '/oilCard-manage/manage' }, { meta: { title: '油站管理' } }])
    },
    methods:{
    	onSelected(data) {
	        // alert(data.province + ' | ' + data.city + ' | ' + data.area)
	    },
	    GasStationDetails(gasStationID){
 	          return new Promise((resolve, reject) => {
	       	    this.loading = true;
			        getGasStation(gasStationID).then(res => {
			             this.loading = false;
				            if(res.code === 1){
											 this.tableDataDetail=res.content
											 console.log(this.tableDataDetail)
				            	 this.logoImg=res.content.logoSmall
				            	 this.oilname=res.content.name
				            	 this.address=res.content.address
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			               reject(error)
			        })
		        })
        },
        saveBtn(){
        	var obj={
    		    name:name,
			      provinceId:provinceId,
			      cityId:cityId,
			      districtId:districtId,
			      address:address,
			      type:type,
			      brandType:brandType
        	}
        	this.GasStationSave(id, obj)
        },
        GasStationSave(id, obj){
 	          return new Promise((resolve, reject) => {
	       	    this.loading = true;
			        getGasStation(id, data).then(res => {
			        	 console.log(res)
			             this.loading = false;
			            if(res.code === 1){
			                this.$message('保存成功');
			            }
			            resolve(res)
			         }).catch(error => {
			         	 this.loading = false;
			             reject(error)
			          })
		        })
        },
        chooseWays(value){
        	console.log(value)
        },
        chooseOilstation(value){
        	console.log(value)
        },
        chooseBrand(value){
        	console.log(value)
        }
    },
    mounted(){
    	 this.gasStationId = this.$route.query.id
//  	   console.log(this.$route.query.id)
         this.GasStationDetails(this.gasStationId)
    }
  }
</script>
<style scoped lang="scss">
.el-form-item{
   margin:0 45px 10px 0; 
}
.kuang{
	padding: 0 50px 50px 50px;
	p{
		width: 70%;
		padding-bottom: 10px;
		display: flex;
	    .imgLogo{
			width: 60px;
			height: 60px;
			border-radius: 50%;
		}
		label{
			font-size: 15px;
			line-height: 35px;
		}
		span{
			font-size: 15px;
			margin-left: 30px;
		}
		.getInfo{
			font-size: 15px;
			color:#409EFF;
			cursor:pointer;
		}
		.addressTextarea{
		   padding:15px;
			 color: #606266;
  	}
	}
}
</style>