import request from "@/utils/request";
/**
 * 获取充值记录列表
 */

export function getRechargeList(startAddTime, endAddTime, gardNo, fromRealname, fromMobile, state, size, page) {
  return request.get("/oilCard/api/recharges", {
  		params: {
	  		startAddTime:startAddTime,
	  		endAddTime:endAddTime,
	  		gardNo:gardNo,
	  		fromRealname:fromRealname,
			mobile:fromMobile,
	  	    state:state,
		  	size:size,
		  	page:page
		}
  	
  });
}




/**
 * 获取充值记录详情信息
 */
export function getRecharge(id) {
   return request.get("/oilCard/api/recharge/"+id, {});
}
