import request from "@/utils/request";
/**
 * 获取发票列表
 */
export function getInvoiceBillList(size, page) {
   return request.get("/oilCard/api/invoice/list", { 
   	params: {
   	 	size:size,
		page:page
    } 
   });
}

/**
 *  发票列表邮寄
 */
//export function getGasBillPost(id) {
//return request.get("/invoice/"+ id +"/delivery", {});
//}
export function getGasBillPost(id, deliveryCompany, deliveryNo, deliveryStatus) {
	return request({
	    url: "/oilCard/api/invoice/"+ id +"/delivery",
	    method: "post",
	    data: {
	        deliveryCompany: deliveryCompany,
		    deliveryNo: deliveryNo,
		    deliveryStatus: deliveryStatus
	    }
	})
}
/**
 *  发票列表审核
 */
//export function getGasBillPost(id) {
//return request.get("/invoice/"+ id +"/delivery", {});
//}
export function getGasBillaudit(id, failReason, auditStatus, amount) {
	return request({
	    url: "/oilCard/api/invoice/status",
	    method: "post",
	    data: {
	    	id: id,
	        failReason: failReason,
		    auditStatus: auditStatus,
		    amount:amount
	    }
	})
}
/**
 * 获取发票详情列表
 */
export function getGasBill(id) {
  return request.get("/oilCard/api/invoice/"+id, {});
}

/**
 * 获取发票详情金额
 */
export function getGasBillbalance() {
  return request.get("/oilCard/api/userRecharge", {});
}

/**
 * 获取发票消费金额
 */
export function getGasBillConsumptionBalance() {
  return request.get("/oilCard/api/invoicable-amount", {});
}


/**
 * 设置合同有效期
 */

export function getGasBillTime(userId, startContract, endContract) {
  return request.get("/oilCard/api/user/save/contract?userId="+ userId +"&startContract="+ startContract +"&endContract="+ endContract, {});
}


/**
 * 获取已开发票列表 
 */
export function getInvoiceUserIDList(userId) {
	return request.get("/oilCard/api/user/invoice/recharges/"+ userId, {});
}

