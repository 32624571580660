<template>
  <div class="app-main">
    <div class="body">
			      <el-form inline class="my-form">
			        <el-form-item label="油卡编号：">
			          <el-input size="small" v-model="cardNumber" placeholder=""></el-input>
			        </el-form-item>
			        <el-form-item label="持卡方信息：">
			          <el-input size="small" v-model="CardholderInfo" placeholder=""></el-input>
			        </el-form-item>
			        <el-form-item label="用户名称：">
			          <el-input size="small" v-model="inputUsername" placeholder=""></el-input>
			        </el-form-item>
			        <el-form-item label="手机号：">
			          <el-input size="small" v-model="telPhoneNumber" placeholder=""></el-input>
			        </el-form-item>
			        <el-form-item label="油卡类型：">
			          <el-input size="small" v-model="oilCardType" placeholder=""></el-input>
			        </el-form-item>
			        <el-form-item>
			          <el-button size="small" type="primary" @click.stop="findData">查询</el-button>
			          <el-button size="small" @click.stop="resetBtn">重置</el-button>
			          <el-button size="small">导出</el-button>
			        </el-form-item>
			      </el-form>
			      <!--<div class="all-money">
			        <span v-if="cardType=='我发出的油卡'">我发出去的油卡总金额：<span>11212.2（元）</span></span>
			        <span v-if="cardType=='我收到的油卡'">我收到的油卡总金额：<span>11212.2（元）</span></span>
			      </div>-->
       </div>
       
	     <div class="body m-t20">
					  <page-table :data="tableData" :columns="columns" :pagination="pagination" :loading="loading" @size-change="pageSizeNum"  @current-change="currentPageNum">
					      <el-table-column label="操作" align="center" style="width: 500px;">
			                <template slot-scope="scope">
					                  <el-tag @click.stop="runWater(scope.$index, scope.row)" class="btnlist">流水</el-tag>
						                <el-tag @click.stop="frozeBtn(scope.$index, scope.row)" class="btnlist">{{scope.row.state === 1 ? '冻结' : '解冻'}}</el-tag>
						                <el-tag @click.stop="ModifyMoney(scope.$index, scope.row)" class="btnlist">修改金额</el-tag>
						                <el-tag @click.stop="rechargeBtn(scope.$index, scope.row)" class="btnlist">充值</el-tag>
					            </template>
					      </el-table-column>
			      </page-table>
	     </div>
    
	    <v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" @update:dialogVisible="dialogVisibles" @update:confirmdialogVisible="confrmdialogVisibles">
		     	   <div class="dialog_content" v-show="modifyStatus">
		     	   	  <p>现在的余额：{{allbalance}}</p>
		     	   	  <p>
		     	   	  	  <el-button size="medium" @click.stop="addStatus(1)" class="{active:actives == 1}">增加</el-button>
		     	   	  	  <el-button size="medium" @click.stop="addStatus(2)" class="{active:actives == 2}">减少</el-button>
		     	   	 
		     	   	  </p>
		     	   	  <p class="dialog_list">
		     	   	  	<label>原因：</label>
		     	   	  	<el-input placeholder="请输入原因" v-model="inputcause" clearable></el-input>
		     	   	  </p>
		     	   	  <p class="dialog_list">
		     	   	  	<label>金额：</label>
		     	   	  	<el-input placeholder="请输入金额" v-model="inputMoney" @input="checkMoney" clearable></el-input>
		     	   	  </p>
		     	   	  <p>
		     	   	  	  <el-checkbox v-model="checkedInvoice">是否跟发票产生关联</el-checkbox>
		     	   	  </p>
		     	   </div>
		     	   
		     	   <div class="rechargeBalance" v-show="rechargeStatus">
		     	   	  <p>油卡编号：{{no}}</p>
		     	   	  <p class="dialog_list">
		     	   	  	<label>充值金额：</label>
		     	   	  	<el-input placeholder="充值金额（元）" v-model="rechargeBalanceValue" clearable></el-input>
		     	   	  </p>
		     	     	<p class="dialog_list">
		     	     		 <label>充值优惠：</label>
		     	     		 <div>
		     	     		 	<el-radio v-model="radio" label="1">需要发票,充值返1%</el-radio>
	                  <el-radio v-model="radio" label="2">无需发票,充值返9%</el-radio>
		     	     		 </div>
		     	   	  </p>
		     	   </div>
		  </v-dialog>
  </div>
</template>
<script>
  import { getGasCardList, getGasCard, FrozenCard, changeBalance, findData, rechargeBalance } from "@/api/card";
  import pageTable from '../../components/pageTable'
  import VDialog from '../../components/dialog'
  export default {
    name: 'manage',
    inject:['reload'],
    data() {
      return {
//      card: {
//        balance: 5700
//      },
        cardType: '我发出的油卡',
        tableData: [],
        columns: [
          {
            name: '油卡编号',
						value:'no',
						 width:'180'
          },
          {
            name: '持卡方信息',
            value:'fromCompany',
						width:'180'
          },
          {
            name: '姓名',
            value:'user'
          },   
          {
            name: '手机号码',
            value:'userPhone',
          
          },
          {
            name: '油卡类型',
            value:'type'
          },
          {
            name: '余额（元）',
            value:'balance',
          },
          {
            name: '已使用（元）',
            value: 'used'
          }
        ],
        pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage: 0,
          size:0
        },
        loading: false,
		dialogVisible: false,
		dialogInfo:'',
		inputcause:'',
		inputMoney:'',
		allbalance:0,
		modifyStatus:false,
		rechargeStatus:false,
		rechargeBalanceValue:'',
		radio:'1',
		no:'',
		cardNumber:'',
		CardholderInfo:'',
		inputUsername:'',
		telPhoneNumber:'',
        oilCardType:'',
        rechargeUserId:0,
        rechargeGasCardId:0,
        addMoneyStatus:false,
	    minMoneyStatus:false,
        userId:0,
        ALLinputMoney:'',
        checkedInvoice:false
      }
    },
    methods: {
    	findData(){
    	 	this.findDataList(this.cardNumber, this.inputUsername, this.telPhoneNumber, this.oilCardType)
    	},
    	resetBtn(){
         var self=this
    	   self.reload () 
    	},
      addStatus(index){
        if(index == 1){
        	 	this.addMoneyStatus = true
	          this.minMoneyStatus = false
        }else{
        	 	this.addMoneyStatus = false
	          this.minMoneyStatus = true
        }
	      // console.log(this.addMoneyStatus)
	      // console.log(this.minMoneyStatus)
      },

      checkMoney(){
      	  	this.inputMoney = this.inputMoney.replace(/[^\d]/g, '')
      },
      oilCardList(size, page){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasCardList(size, page).then(res => {
			              this.loading = false;
				            if(res.code === 1){
											 this.tableData = res.content
				             	 this.pagination.total = res.totalElements
				            	 this.pagination.size = res.totalPages
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },
      
      findDataList(no, realname, mobile, type){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        findData(no, realname, mobile, type).then(res => {
			              this.loading = false;
				            if(res.code === 1){
				            	 this.tableData = res.content
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },
      forze(no,state){
      	console.log(no,state)
      	  return new Promise((resolve, reject) => {
    	       	  this.loading = true;
				        FrozenCard(no,state).then(res => {
				              this.loading = false;
				              if(res.content==0){
				              	
				              	this.$message('已解冻成功');
				              }else{
				              	
				              	this.$message('冻结成功');
				              }
				              this.oilCardList()
					            resolve(res)
				        }).catch(error => {
				         	   this.loading = false;
				             reject(error)
				        })
		      })
      },
      ModifyMoneyfun(id, nonumber, remarkesg, DoublebalanceMoney){
      	  return new Promise((resolve, reject) => {
    	       	  this.loading = true;
				        changeBalance(id, nonumber, remarkesg, DoublebalanceMoney).then(res => {
				              this.loading = false;
				              this.$message('修改余额成功');
				              this.oilCardList()
					            resolve(res)
				        }).catch(error => {
				         	   this.loading = false;
				             reject(error)
				        })
		      })
      },
      rechargeBalanceMoney(nonumber, typeValue, DoublebalanceMoney, rechargeUserId, rechargeGasCardId){
      	  return new Promise((resolve, reject) => {
    	       	  this.loading = true;
				        rechargeBalance(nonumber, typeValue, DoublebalanceMoney, rechargeUserId, rechargeGasCardId).then(res => {
				              this.loading = false;
				              this.$message('充值金额成功');
				              this.oilCardList()
					            resolve(res)
				        }).catch(error => {
				         	   this.loading = false;
				             reject(error)
				        })
		      })
      },
      runWater(event,value) {
         this.$router.push({path:'/oilCard-manage/manageDetail',query:{id:value.gasCardId}}) 
      },
      
      frozeBtn(event, value){
      	 this.forze(value.no,value.state)
      },
      
      ModifyMoney(event, value){
      	 this.userId=value.gasCardId
      	 this.allbalance=value.balance
      	 this.modifyStatus=true
	       this.rechargeStatus=false
      	 this.dialogVisible = true
      	 this.dialogInfo='详细信息'
      },
      
      rechargeBtn(event, value){
      	 this.rechargeUserId = value.user.userId
      	 this.rechargeGasCardId = value.gasCardId
      	 this.no=value.no
         this.dialogVisible = true;
         this.modifyStatus=false
	       this.rechargeStatus=true
	       this.dialogInfo='详细信息'
      },
      
      pageSizeNum(value){
      	//  console.log(value)
      	 this.pagination.pageSize=value
      	 this.oilCardList(this.pagination.pageSize,this.pagination.currPage)
      },
      currentPageNum(event, value){
      	//  console.log(event)
      	 this.pagination.currPage=event
      	 this.oilCardList(this.pagination.pageSize,this.pagination.currPage)
      },
   	 //子组件传 过来的 数据
	    dialogVisibles(v){
	      this.dialogVisible = v
	    },
	    confrmdialogVisibles(v){
	    	 if(this.modifyStatus==true){
	    	 	  if(this.inputMoney=="" || this.inputMoney==null){
	    	 	  	this.$message.error('充值金额不能为空');
	    	 	  	return
	    	 	  }else if(this.addMoneyStatus==false && this.minMoneyStatus==false){
	    	 	  	this.$message.error('请选择添加或者抵扣');
	    	 	  	return
	    	 	  }else{
	    	 	  	
		    	 	  if(this.addMoneyStatus==true){
		    	 	  	   this.ALLinputMoney = this.inputMoney
                  //  console.log(this.userId)
                  //  console.log(this.inputcause)
		    	 	  	  //  console.log(this.ALLinputMoney)
		    	 	  	  //  console.log(this.checkedInvoice)
		    	 	  	   this.ModifyMoneyfun(this.userId, this.inputcause, this.ALLinputMoney,this.checkedInvoice)
		    	 	  	  
		    	 	  }else{
	                //  console.log(this.inputMoney)
	                 this.ALLinputMoney='-'+ this.inputMoney
		    	 	       this.ModifyMoneyfun(this.userId, this.inputcause, this.ALLinputMoney,this.checkedInvoice)
		    	
		    	 	  }
	    	 	  }
//	    	 	  this.dialogVisible = v

	    	 }else if(this.rechargeStatus==true){
		    	 	if(this.rechargeBalanceValue=="" || this.rechargeBalanceValue==null){
		    	 		  this.$message('充值金额不能为空！');
		    	 		
		    	 	}else{
		 		   	 	 this.rechargeBalanceMoney(this.no, this.radio, this.rechargeBalanceValue, this.rechargeUserId, this.rechargeGasCardId)
		 	         this.dialogVisible = v
		    	 		
		    	 	}
	    	 }
	    }
    },
    components: { pageTable,VDialog },
    filters: {
      money(n) {
        let num = n.toFixed(2)
        let numInt = num.split('.')[0]
        let numFloat = num.split('.')[1]
        let numIntArr = numInt.split('')
        let numIntArrReverse = numIntArr.reverse()
        let arr = []
        numIntArrReverse.map((item, index) => {
	          arr.push(item)
	          if ((index + 1) % 3 == 0) {
	            arr.push(',')
	          }
        })
        return arr.reverse().join('') + '.' + numFloat
      }
    },
    mounted(){
        this.oilCardList()
    }
  }
</script>
<style lang="scss" scoped>
  .head {
    background: #fff;
    display: flex;
    padding-left: 20px;
    align-items: center;
    height: 100px;
    .money {
      vertical-align: baseline;
      margin: 0 20px;
      span {
        font-size: 20px;
      }
    }
  }
  .el-form-item {
    margin-top: 22px;
    margin-bottom: 0;
  }
  .all-money {
    margin: 5px 0;
    font-size: 14px;
    color: #817979;
    text-align: right;
  }
  .dialog_content p{
  	padding-bottom: 15px;

  }
  .dialog_list{
  	display: flex;
    label{
  		width: auto;
  		line-height: 35px;
  	}
  }

  
</style>