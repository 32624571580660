<template>
  <div class="app-main">
  	<div>
  		  <div class="business-card">
			      <div class="card-body">
						      <div class="card-body-itemlist">
	            				<div class="card-body-item-name">增票充值金额</div>
					            <div class="card-body-item-money">{{this.balance.toFixed(2)}}元</div>
			            </div>
						      <div class="card-body-itemlist">
	            				<div class="card-body-item-name">消费金额</div>
					            <div class="card-body-item-money">{{this.consumption}}元</div>
			            </div>
						      <div class="card-body-itemlist">
	            				<div class="card-body-item-name">已开票金额</div>
					            <div class="card-body-item-money">{{this.openInvoiceBalance.toFixed(2)}}元</div>
			            </div>
						      <div class="card-body-itemlist" style="border-right:none">
		            			<div class="card-body-item-name">最大可开票金额</div>
						          <div class="card-body-item-money">{{this.openInvoiceBalance.toFixed(2)}}元</div>
			            </div>
			      </div>
			      
			      <div class="invoiceBill">
			      	 <div class="title">可开票金额说明</div>
			      	 <div>1.未开票消费金额=消费金额-已开票金额&nbsp;2.当未开票消费金额>（增票充值金额总额-已开票金额），则可开票金额=（增票充值金额总额-已开票金额）3.当未开票消费金额<（增票充值金额总额-已开票金额），则可开票金额=未开票消费金额4.自由卡分配或者消费的金额可开票5.充值时选择无需开票的金额不可开票6.充值时赠送的金额不可开票7.转账卡消费金额不可开票</div>
			      	 <div>例如:</div>
			      	 <div>充值1000赠送10元,可开票金额为1000元，若分配出去1010元，则最大可开票金额为1000元，若分配出去500元，则最大可开票金额为500元</div>
			      </div>
	    </div>
  		
			<div class="head">
			    	<div class="invoice_content" v-for="(item,index) in this.invoiceInfo" :key="index">
			    		  	<div class="invoiceTime">
			    		  		<div >
			    		  			合同有效期:
			    		  			<span  class="timeColorStatus">{{contractLife}}</span>
			    		  			<!--<span v-if="timeStatusFir">暂未签订合同</span>
			    		  			<span v-if="timeStatusSec">合同有效期已过，请重新设置合同有效期</span>-->
			    		  		</div>
			    		  	</div>
			    		  	
						      <div class="invoiceList">
						      	  <div class="invoiceTitle">发票类型</div>
						          <div class="invoiceContent">
						          	<el-radio v-model="radiom" label="1">增值税专用发票</el-radio>
						          </div>
						          <div class="invoiceTitle">发票抬头</div>
						          <div class="invoiceContent" style="width:18%">{{item.invoiceLookUp}}</div>
						          <div class="invoiceTitle">申请开票金额(元)</div>
						          <div class="invoiceContent" style="border-right: none">{{item.amount}}</div>
						      </div>
						      
						      <div class="invoiceList COLORBG">
						      	  <div class="invoiceTitle">纳税人识别</div>
						          <div class="invoiceContent">{{item.identifier}}</div>
						          <div class="invoiceTitle" >开户行账号</div>
						          <div class="invoiceContent" style="width:18%">{{item.bankName}}</div>
						          <div class="invoiceTitle">地址电话</div>
						          <div class="invoiceContent" style="border-right: none;  overflow: hidden;text-overflow: ellipsis;display: -webkit-box;
 -webkit-box-orient: vertical;">{{item.address}}</div>
						      </div>
						      
						      <div class="invoiceList">
						      	  <div class="invoiceTitle">收取发票地址</div>
						          <div class="invoiceContent">{{item.mailAddress}}</div>
						          <div class="invoiceTitle">收货人</div>
						          <div class="invoiceContent" style="width:18%">{{item.name}}</div>
						          <div class="invoiceTitle">收货电话</div>
						          <div class="invoiceContent" style="border-right: none">{{item. phone}}</div>
						      </div>
						      
						      <div class="invoiceList COLORBG">
						      	  <div class="invoiceTitle">备注</div>
						          <div class="invoiceContent" style="border-right: none" v-if="item.remark!==null">{{item.remark}}</div>
						          <div class="invoiceContent" style="border-right: none" else>用户没有填写备注信息</div>
						      </div>
						      
						      <div class="invoiceList">
						      	  <div class="invoiceTitle">开票状态</div>
						          <div class="invoiceContent colorSTATUS" >
						                 <span v-if="item.status=='1'">已开发票（邮寄）</span>
									           <span v-if="item.status=='2'">已开发票（未邮寄）</span>
									           <span v-if="item.status=='3'">未开发票</span>
									           <span v-if="item.status=='4'">开票失败</span>
									           <span v-if="item.status=='5'">已开发票（无需邮寄）</span>
						          </div>
						          <div class="invoiceTitle">合同邮寄快递公司</div>
						          <div class="invoiceContent"></div>
						          <div class="invoiceTitle">合同邮寄快递单号</div>
						          <div class="invoiceContent" style="border-right: none"> 
						          	<el-button type="primary"  round size  @click.stop="handleCopy(7188423656652)">复制</el-button>
						          </div>
						      </div>
						      
						      <div class="invoiceList COLORBG" style="border-bottom: 1px solid #C8C8C8;">
						      	  <div class="invoiceTitle">申请发票用户</div>
						          <div class="invoiceContent"></div>
						          <div class="invoiceTitle">发票邮寄快递公司</div>
						          <div class="invoiceContent">{{item.deliveryCompany}}</div>
						          <div class="invoiceTitle">发票邮寄快递单号</div>
						          <div class="invoiceContent" style="border-right: none">{{item.deliveryNo}}</div>
						      </div>
						      
						      <div class="btnStatus">
						      	   <el-button type="primary" round  @click.stop="postWay()">开票审核</el-button>
						      	   <el-button type="primary" round  @click.stop="checkAudit()">邮寄发票</el-button>
						      	   <el-button type="primary" round  @click.stop="setContractTime()">设置合同有效期</el-button>
						      </div>
			    	</div>
			    </div>
			    
			    <div class="body m-t20" style="padding: 0px 0px 20px 0px;">
				    	 <div class="btn_po">
				  		     <span>可开票明细</span>
						</div>
			         <page-table :data="tableData" :columns="columns"  @size-change="pageSizeNum"  @current-change="currentPageNum" :pagination="pagination"  @handleSelectionChange="handleSelectionChange" :loading="loading" :selection="selection" ></page-table>
			    </div>
			 	<div style="padding:20px">
                    <span>待开票金额：</span>
					<span style="color:red;font-size:18px">{{this.invoiceAllMoney}}</span>元
				 </div>
   </div>
   
	 <v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" @update:dialogVisible="dialogVisibles" @update:confirmdialogVisible="confrmdialogVisibles">
	     	   <div class="dialog_content" v-show="modifyStatus">	     	   	 
	     	   	  <p class="dialog_list">
	     	   	  	  <label>邮寄方式：</label>
	     	     		 	<el-radio v-model="radio" label="1">普通快递</el-radio>
                  <el-radio v-model="radio" label="2">无需邮寄</el-radio>
	     	   	  </p>
	     	   	  <p class="dialog_list" v-if="radio=='1'">
	     	   	  	<label>快递单号：</label>
	     	   	  	<el-input placeholder="请输入" v-model="postNo" clearable></el-input>
	     	   	  </p>
	     	   	  <p class="dialog_list" v-if="radio=='1'">
	     	   	  	<label>快递公司：</label>
	     	   	  	<el-input placeholder="请输入" v-model="postCompany" clearable></el-input>
	     	   	  </p>
	     	   </div>
	     	   
	     	   <div class="rechargeBalance" v-show="rechargeStatus">
	     	   	  <p class="invoiceTitle">
	     	   	  	    <el-radio v-model="auditStatus" label="1">审核通过</el-radio>
                    <el-radio v-model="auditStatus" label="2">审核驳回</el-radio>
	     	   	  </p>
	     		    <p class="dialog_list" v-if="auditStatus=='2'">
	     	     		 <label >驳回原因：</label>
	     	     		 <el-input type="textarea" v-model="desc"></el-input>
	     	   	  </p>
	     	   </div>
	     	     
	     	   <div class="rechargeBalance" v-show="rechargeTimeStatus">
	     	   	  <p style="padding-bottom:10px;">请选择合同有效期:</p>
	     	   	  <p class="invoiceTitle">
	     	          <time-picker @timeValue="getValue(arguments)"></time-picker>
	     	   	  </p>
	     	   </div>
	 </v-dialog>
  </div>
</template>
<script>
  import { getInvoiceBillList, getGasBill, getGasBillPost, getGasBillaudit, getGasBillbalance, getGasBillConsumptionBalance, getGasBillTime, getInvoiceUserID,getInvoiceUserIDList } from "@/api/invoice";
  import pageTable from '../../components/pageTable'
  import timePicker from '../../components/timePicker'
  import VDialog from '../../components/dialog'
  import Clipboard from 'clipboard'
  export default {
    name: 'manage',
    data() {
      return {
        invoiceInfo:[],
        tableData: [],
        columns: [
          {
            name: '订单编号',
            value: 'invoiceOpenDetail'
          },
          {
            name: '开票主体',
            value: 'invoiceMain'
          },
          {
            name: '类型',
            value: 'category'
          },    
          {
            name: '订单实付金额',
            value: 'invoiceOpenOrderPrice'
          },
          {
            name: '可开票金额(元)',
            value: 'invoiceOpenPrice'
          },
          {
            name: '原始订单编号',
            value: 'no'
          },
          {
            name: '实例订单ID',
            value: 'rechargeId'
          },
          {
            name: '订单支付时间',
            value: 'updateTime'
          }
        ],

        loading:false,
        pagination:{},
        postNo:'',
        postCompany:'',
        desc:'',
        showPagination:false,
        radio:"1",
        radiom:'1',
        inputNo:'',
        inputCompany:'',
        dialogVisible:false,
	    dialogInfo:'',
        modifyStatus:false,
        rechargeStatus:false,
        rechargeTimeStatus:false,
        invoiceid:"",
        copyData:null,
        timeValueFir:'',
		timeValueSec:'',
		timeStatus:false,
		timeStatusFir:false,
		timeStatusSec:false,
		auditStatus:"2",
		balanceAmount:[],
		consumption:'',
		balance:'',
		openInvoiceBalance:'',
		canInvoiceBalance:'',
		contractLife:'',
		amount:'',
		invoiceUserID:'',
		selection:true,
	    pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 3,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage:0,
          size: 0
        },
		invoiceAllMoney:0.00,
		invoiceOpenUserID:''
      }
    },
    methods: {
    	getValue(data){
    		this.timeValueFir=data[0]
	      this.timeValueSec=data[1]
    	},
      postWay(){

        this.dialogVisible=true
        this.modifyStatus=false
	      this.rechargeStatus=true
	      this.rechargeTimeStatus=false
      	this.dialogInfo='审核'
      },
      checkAudit(){
        this.dialogVisible=true
        this.modifyStatus=true
	      this.rechargeStatus=false
	      this.rechargeTimeStatus=false
        this.dialogInfo='邮寄'
        
      },
      setContractTime(){
          this.dialogVisible=true
          this.modifyStatus=false
	      this.rechargeStatus=false
	      this.rechargeTimeStatus=true
      	  this.dialogInfo='设置合同有效期'
      },

      invoiceListDetail(id){
      	    var tableArr=[]
 	          return new Promise((resolve, reject) => {
	       	    this.loading = true;
			        getGasBill(id).then(res => {
			             this.loading = false;
				            if(res.code === 1){
				            	 this.invoiceUserID=res.content.user.userId
				            	 tableArr.push(res.content)
				            	 this.invoiceInfo=tableArr
   			            	     this.contractLife=res.content.user.contractValidity
				            	 if(this.contractLife=="" || this.contractLife==null){
				            	 	    this.contractLife="暂未签订合同"  
				            	 }else{
	            	 	         this.timeValueFir= this.contractLife.split("~")[0]
			                     this.timeValueSec= this.contractLife.split("~")[1]
			                     console.log(this.timeValueFir,this.timeValueSec)
						               this.timeValue(this.timeValueFir,this.timeValueSec)
				            	 }
				            }
				            resolve(res)
			         }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			          })
		        })
      },
      
      invoiceListDetailBalance(){
 	          return new Promise((resolve, reject) => {
	       	    this.loading = true;
			        getGasBillbalance().then(res => {
			              this.loading = false;
			      	      this.balance=res.content.balance
							      this.openInvoiceBalance=res.content.openInvoiceBalance
							      this.canInvoiceBalance=res.content.canInvoiceBalance
				            resolve(res)
			         }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			          })
		        })
      },
      
      invoiceListDetailConsumptionBalance(){
   	          return new Promise((resolve, reject) => {
	       	    this.loading = true;
			        getGasBillConsumptionBalance().then(res => {
			             this.loading = false;
				            if(res.code === 1){
                              this.consumption=res.content
				            }
				            resolve(res)
			         }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			          })
		        })
      },

      handleCopy(value){
      	 this.copyData = value
         this.copy(this.copyData)
      },
      
      copy(data){
        let url = data;
        let oInput = document.createElement('input');
        oInput.value = url;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象;
        console.log(oInput.value)
        document.execCommand("Copy"); // 执行浏览器复制命令
        this.$message({
          message: '复制成功',
          type: 'success'
        });
        oInput.remove()
     },
           
      getGasBillPostList(id, deliveryCompany, deliveryNo, deliveryStatus){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasBillPost(id, deliveryCompany, deliveryNo, deliveryStatus).then(res => {
			              this.loading = false;
				            if(res.code === 1){
				            	 	 this.$message({
									          message: '邮寄成功',
									          type: 'success'
									        })
				            	this.invoiceListDetail(this.invoiceid)
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },
      
      getGasBillauditList(id, failReason, auditStatus, amount){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasBillaudit(id, failReason, auditStatus, amount).then(res => {
			              this.loading = false;
				            if(res.code === 1){
				            	  this.$message({
									          message: '审核成功',
									          type: 'success'
									      })
				            	 	this.invoiceListDetail(this.invoiceid)
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },
      
      getGasBillTimeList(userId, startContract, endContract){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasBillTime(userId, startContract, endContract).then(res => {
			              this.loading = false;
			            //   console.log(res)
				            if(res.code === 1){
				            	 	this.$message({
									          message: '设置合同时间成功',
									          type: 'success'
									      })
//				            	 	this.invoiceListDetail(this.invoiceid)
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },
// getInvoiceUserIDList
// getInvoiceUserID

      getInvoiceUserIDdata(userId){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getInvoiceUserIDList(userId).then(res => {
			              this.loading = false;
						   console.log('999')
			               console.log(res)
				            if(res.code === 1){
				            	 	
                               this.tableData=res.content
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },

	//   勾选数据行的 Checkbox 时触发的事件
	//   rowClassName ({ row, rowIndex }) {
	//       if (this.selectRow.includes(rowIndex)) {
	//         return 'slecleRowColor ';
	//       }
	//   },

     pageSizeNum(value){
        // console.log(value)
        // this.pagination.pageSize=value
     },

     currentPageNum(event, value){
        // this.pagination.currPage=event
        // console.log(this.pagination.currPage)
     },

      handleSelectionChange(value){
        console.log(value)
        let sum = 0
		if(value.length > 0){
			value.forEach((column, index) => {
				sum+=Number(column.price)
				console.log(sum)
				this.invoiceAllMoney = sum.toFixed(2)
			})
		}else{
            this.invoiceAllMoney = 0.00
		}
	  },

     //子组件传 过来的 数据
	   dialogVisibles(v){
	      this.dialogVisible = v
	   },
	   
	   confrmdialogVisibles(v){
	      if(this.modifyStatus==true){
	       	     	this.getGasBillPostList(this.invoiceid, this.postCompany, this.postNo, this.radio)
	    	 	    this.dialogVisible = v
	    	 	  
	    	 }else if(this.rechargeStatus==true){
		 		   	   this.getGasBillauditList(this.invoiceid, this.desc, this.auditStatus, this.amount)
		 	         this.dialogVisible = v
		 	         
	    	 }else if(this.rechargeTimeStatus=true){
	    	 	
	    	 	   this.getGasBillTimeList(this.invoiceUserID, this.timeValueFir, this.timeValueSec)
	    	 	   
	    	   	this.dialogVisible = v
	    	 }
	    },
	    //判断本地时间是否在 时间区间内
			//time1 开始日期 
			//time2 结束日期
		  timeValue(time1,time2){
			    var time = Date.parse( new Date());
			    var date1 = Date.parse(new Date(time1.replace(/-/g, '/')));
			    var date2 = Date.parse(new Date(time2.replace(/-/g, '/')));
			
			    if (date1<time && date2>time) {
              this.contractLife=time1+"~"+time2
              console.log(this.contractLife)
			    }else if(date1<time && date2<=time){
				      this.contractLife="合同有效期已过，请重新设置合同有效期"
				      console.log(this.contractLife)
			   }
               // return false;
			 }
    },
    components: { pageTable, timePicker, VDialog },
    filters: {
      money(n) {
        let num = n.toFixed(2)
        let numInt = num.split('.')[0]
        let numFloat = num.split('.')[1]
        let numIntArr = numInt.split('')
        let numIntArrReverse = numIntArr.reverse()
        let arr = []
        numIntArrReverse.map((item, index) => {
          arr.push(item)
          if ((index + 1) % 3 == 0) {
            arr.push(',')
          }
        })
        return arr.reverse().join('') + '.' + numFloat
      }
    },
   mounted(){
    	this.invoiceid = this.$route.query.id
    	console.log(this.invoiceid)
	    this.invoiceOpenUserID = this.$route.query.inVoiceUserID
    	console.log(this.invoiceOpenUserID)

        this.invoiceListDetail(this.invoiceid)
        this.invoiceListDetailBalance()
        this.invoiceListDetailConsumptionBalance()
		this.getInvoiceUserIDdata(this.invoiceOpenUserID)
    }
  }
</script>
<style lang="scss" scoped>
  .business-card{
  	margin: 20px;
  	.card-body{
  		border:1px solid #ccc;
  		.card-body-itemlist{
	  		width: 25%;
	  		text-align: center;
	  		border-right: 1px solid #ccc;
	  		padding:15px 0;
	  		.card-body-item-name{
	  			color:#409EFF;
	  			font-size: 18px;
	  		}
	  		.card-body-item-money{
	  			font-size: 28px;
	  			color: #333;
	  		}
	  	}
  	}
  	
  	.invoiceBill{
  		padding: 20px 50px;
  		border: 1px solid #ccc;
    	border-top: none;
  		.title{
  			color: #333;
  			font-size: 14px;
  		}
  	}
  }
  .head {
    background: #fff;
    display: flex;
    padding-left: 20px;
    justify-content: center;
    .invoice_content{
      flex: 1;
      justify-content: center;
      align-items: center;
    	padding:20px;
    	.invoiceTime{
    		display: flex;
    		padding-bottom: 20px;
    		line-height: 35px;
    		padding-right: 15px;
    		font-size: 14px;
    		.timeColorStatus{
    			color: red;
    			font-size: 14px;
    		}
    	}
      .invoiceList{
      	 display: flex;
      	 height: 40px;
      	 line-height: 40px;
      	 text-align: center;
         border: 1px solid #C8C8C8;
         border-bottom: none;
      	 .invoiceTitle{
						border-right: 1px solid #C8C8C8;
						width: 10%;
      	 }
      	 .invoiceContent{
      	 		width: 23.3%;
      	 		border-right: 1px solid #C8C8C8;
      	 }
      	 .colorSTATUS{
      	 	  color: #008000;
      	 }
      }
      .COLORBG{
      	background: #dddddd;
      }
      .btnStatus{
      	text-align: center;
      	margin-top:20px;
      }
    }
  }
  .el-button.is-round {
    border-radius: 20px;
    padding: 5px 15px;
  }
  .btn_po{
  	width: 80px;
  	border-top: 2px solid #409EFF;
  	height: 35px;
  	line-height: 35px;
  	text-align: center;
  	span{
  		 font-size: 14px;
  	}
  }
  .pagination-container{
  	display: none;
  }
</style>