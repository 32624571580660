<template>
  <div class="app-main">
    <div class="business-card">
		      <div class="card-title">
			         <el-button type="primary" icon="el-icon-refresh-right" @click.stop="resetBtn">刷新</el-button>
			    </div>
		      <div class="card-body">

		            
		            <el-card class="box-card" >
					       	<div class="card-body-itemlistSec" @click.stop="czbBtn('https://www.czb365.com/home')">
		            				<div class="card-body-item-name">车主邦账户余额</div>
						            <!-- <div class="card-body-item-intro">平台在车主邦账户余额</div> -->
						            <div class="card-body-item-money">
	                         进入车主邦平台
						            </div>
		            	</div>
								</el-card>
		            

			

		            <el-card class="box-card">
					      	<div class="card-body-itemlistFou" @click.stop="wjyBtn('https://vip.nucarf.net/Index/statis.html')">
		            				<div class="card-body-item-name">万金油账户余额</div>
						            <!-- <div class="card-body-item-intro">平台在万金油账户余额</div> -->
						            <div class="card-body-item-money">
						                   进入万金油平台
						            </div>
		            	</div>
					</el-card>
		            
					<el-card class="box-card"> 
								<div class="card-body-itemlistFou" style="color:orange" @click.stop="wjyBtn('https://www.51zhaoyou.com/')">
										<div class="card-body-item-name">老吕账户余额</div>
										<!-- <div class="card-body-item-intro">用户万金油加油金额</div> -->
										<div class="card-body-item-money"  style="font-size:30px">进入老吕加油平台</div>
										<!--<div class="card-body-item-money">{{item.money|money}}</div>-->
							</div>
					</el-card>

	      
		            <el-card class="box-card">
				    	    <div class="card-body-itemlistSec">
		            				<div class="card-body-item-name">今日车主邦加油金额</div>
						            <!-- <div class="card-body-item-intro">用户使用车主邦加油金额</div> -->
						            <div class="card-body-item-money">¥{{this.czblistBalance}}</div>
		            	</div>
					</el-card>
		    



		            <el-card class="box-card">
				    	      <div class="card-body-itemlistFou">
		            				<div class="card-body-item-name">今日万金油加油金额</div>
						            <!-- <div class="card-body-item-intro">用户万金油加油金额</div> -->
						            <div class="card-body-item-money">¥{{this.wjylistBalance}}</div>
						            <!--<div class="card-body-item-money">{{item.money|money}}</div>-->
		            	</div>
					</el-card>

                  <el-card class="box-card">
				    	      <div class="card-body-itemlistFou" style="color:orange">
		            				<div class="card-body-item-name">今日老吕加油金额</div>
						            <!-- <div class="card-body-item-intro">用户万金油加油金额</div> -->
						            <div class="card-body-item-money" style="font-size:30px">¥{{this.LVlistBalance}}</div>
						            <!--<div class="card-body-item-money">{{item.money|money}}</div>-->
		            	</div>
				</el-card>
      	<el-card class="box-card"> 
									<div class="card-body-itemlist"  style="color:orange">
											<div class="card-body-item-name">平台已分配余额</div>
											<!-- <div class="card-body-item-intro">用户今日充值金额</div> -->
											<div class="card-body-item-money">¥{{this.rechargeAllBalance}}</div>
								</div>
						</el-card>
		        <el-card class="box-card"> 
								<div class="card-body-itemlist" style="color:orange">
										<div class="card-body-item-name">全部用户充值余额</div>
										<!-- <div class="card-body-item-intro">用户今日充值金额</div> -->
										<div class="card-body-item-money">¥{{this.transferAllBalance}}</div>
							</div>
				</el-card>
                <el-card class="box-card"> 
					       	<div class="card-body-itemlist" style="color:orange">
		            				<div class="card-body-item-name">账户余额</div>
						             <div class="card-body-item-intro">物流公司账户余额</div>
						            <div class="card-body-item-money">¥{{this.companyAllBalance}}</div>
		            	</div>
								</el-card>
                <el-card class="box-card"> 
					       	<div class="card-body-itemlist" style="color:orange" >
		            			   <div class="card-body-item-name">账户余额</div>
						             <div class="card-body-item-intro">车主账户余额</div>
						             <div class="card-body-item-money">¥{{this.driveAllBalance}}</div>
		            	</div>
				</el-card>

                <el-card class="box-card"> 
					       	<div class="card-body-itemlist" >
		            				<div class="card-body-item-name">今日充值金额</div>
						            <div class="card-body-item-intro">用户今日充值金额</div>
						            <div class="card-body-item-money">¥{{this.todayAllBalance}}</div>
		            	</div>
								</el-card>

                <el-card class="box-card"> 
					       	<div class="card-body-itemlist" style="color:orange">
		            				<div class="card-body-item-name">当月充值金额</div>
						            <div class="card-body-item-intro">用户当月充值金额</div>
						            <div class="card-body-item-money" style="font-size:30px">¥{{this.MonthRechargeBalance}}</div>
		            	</div>
								</el-card>


  

                <el-card class="box-card"> 
					       	<div class="card-body-itemlist" style="color:orange">
		            				<div class="card-body-item-name">当月消费金额</div>
						            <div class="card-body-item-intro">用户当月消费金额</div>
						            <div class="card-body-item-money" style="font-size:30px">¥{{ this.MonthConsumeBalance}}</div>
		            	</div>
								</el-card>
								              <el-card class="box-card"> 
					       	<div class="card-body-itemlist" style="color:orange">
		            				<div class="card-body-item-name">今日消费金额</div>
						            <div class="card-body-item-intro">用户今日消费金额</div>
						            <div class="card-body-item-money" style="font-size:30px">¥{{this.DayConsumeBalance}}</div>
		            	</div>
								</el-card> 
					
					       	<div class="" style="color:orange;width:30%">
		            				<div class="card-body-item-name"></div>
						            <div class="card-body-item-intro"></div>
						            <div class="card-body-item-money" style="font-size:30px"></div>
		            	</div>
						

		      </div>
    </div>
    <div class="business-card m-t20">
			  <my-legend></my-legend>
    </div>
  </div>
</template>
<script>
import { getGasRechargeAllBalance, getGasTransferAllBalance, getGasDriveAllBalance, getGasCompanyAllBalance, getGasCardLVBalance, getGasCardMonthRechargeBalance, getGasCardDayConsumeBalance, getGasCardMonthConsumeBalance, getGasCardAccBalance, getGasCardAllBalance, getGasCardczbBalance, getGasCardetsdBalance, getGasCardwjyBalance, getGaslengend } from "@/api/index";
import Legend from '../components/legend.vue'
export default {
  name: '',
  data() {
    return {
//    cardData: [
//      { name: '大件无忧账户余额', intro: '用户充值金额', money: 12300.00 },
//      { name: '车主邦账户余额', intro: '平台在车主邦账户余额', money: 12300.00 },
//      { name: '山东高速账户余额', intro: '平台在山东高速账户余额', money: 12300 },
//      { name: '万金油账户余额', intro: '平台在万金油账户余额', money: 12300.00 },
//      { name: '大件无忧今日充值金额', intro: '用户今日充值金额', money: 12300.00 },
//      { name: '今日车主邦加油金额', intro: '用户使用车主邦加油金额', money: 12300.00 },
//      { name: '今日山东高速加油金额', intro: '用户使用山东高速加油金额', money: 12300.00 },
//      { name: '今日万金油加油金额', intro: '用户万金油加油金额', money: 12300.00 }
//    ],
      echartsData: [
        { title: '加油金额' },
        { title: '提现金额' },
        { title: '分配金额' }
      ],
      czb:'',
      sdgs:'',
      wjy:'',
      accountBalance:'',
      todayAllBalance:'',
      czblistBalance:'',
      etcsdlistBalance:'',
			wjylistBalance:'',

			LVlistBalance:'',
      MonthRechargeBalance:'',
      DayConsumeBalance:'',
			MonthConsumeBalance:'',

			rechargeAllBalance:'',
			transferAllBalance:'',
			driveAllBalance:'',
		  companyAllBalance:''

    }
  },
  methods:{
			//重置
		resetBtn(){
			var self=this
			self.reload() 
		},
  	czbBtn(url){
  		 window.location.href = url
  	},
  	
  	sdgsBtn(url){
  		window.location.href = url
  	},
  	
  	wjyBtn(url){
  		window.location.href = url
		},
		
  	// 大件无忧账户余额
  	getGasCardAccBalanceList(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasCardAccBalance().then(res => {
			              this.loading = false;
				            if(res.code === 1){
				               this.accountBalance=res.content
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
			},
			
     //大件无忧今天充值总和
      getGasCardAllBalanceList(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasCardAllBalance().then(res => {
			              this.loading = false;
				            if(res.code === 1){
				              this.todayAllBalance=res.content
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
			},
			
      //查询车主邦加油金额
      getGasCardczbBalanceList(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasCardczbBalance().then(res => {
										this.loading = false;
										console.log(res)
				            if(res.code === 1){ 
				              this.czblistBalance=res.content.toFixed(2)
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
			},
			
      //查询山东高速加油金额
      getGasCardetsdBalanceList(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasCardetsdBalance().then(res => {
										this.loading = false;
											console.log(res)
				            if(res.code === 1){
				              this.etcsdlistBalance=res.content.toFixed(2)
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },
      //查询万金油加油金额
      getGasCardwjyBalanceList(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasCardwjyBalance().then(res => {
										this.loading = false;
											console.log(res)
				            if(res.code === 1){
				               this.wjylistBalance=res.content.toFixed(2)
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },
			

			//老吕加油每日加油金额
      getGasCardLVBalance(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasCardLVBalance().then(res => {
										this.loading = false;
				            if(res.code === 1){
				               this.LVlistBalance=res.content.toFixed(2)
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },

     //每月充值金额
      getGasCardMonthRechargeBalance(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasCardMonthRechargeBalance().then(res => {
										this.loading = false;
				            if(res.code === 1){
				               this.MonthRechargeBalance=res.content
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },

   //每日消费金额
      getGasCardDayConsumeBalance(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasCardDayConsumeBalance().then(res => {
										this.loading = false;
				            if(res.code === 1){
				               this.DayConsumeBalance=res.content
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },

      //每月消费金额
      getGasCardMonthConsumeBalance(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasCardMonthConsumeBalance().then(res => {
										this.loading = false;
											// console.log(res)
				            if(res.code === 1){
				               this.MonthConsumeBalance=res.content
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },

/* -------------------------------*/
     //获取充值金额总和
      getGasRechargeAllBalance(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasRechargeAllBalance().then(res => {
										this.loading = false;
										//  console.log(res)
										this.rechargeAllBalance=res
				            if(res.code === 1){
											 this.rechargeAllBalance=res 
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },

     //获取转卡总和
      getGasTransferAllBalance(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGasTransferAllBalance().then(res => {
										this.loading = false;
											// console.log(res)
                      this.transferAllBalance=Number(this.rechargeAllBalance-res.otherAmount-res.driverAmount).toFixed(2)
	                    this.companyAllBalance = res.otherAmount
											this.driveAllBalance = res.driverAmount
											 console.log(this.transferAllBalance)
				            if(res.code === 1){	
				               this.transferAllBalance=res.content
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      },

			//图表
      getGaslengendList(){
	      return new Promise((resolve, reject) => {
	       	  this.loading = true;
			        getGaslengend().then(res => {
			              this.loading = false;
				            if(res.code === 1){
				               console.log(res)
				            }
				            resolve(res)
			        }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			        })
	      })
      }

  },
  components: { MyLegend:Legend },
  filters: {
    money(n) {
      let num = n.toFixed(2)
      let numInt = num.split('.')[0]
      let numFloat = num.split('.')[1]
      let numIntArr = numInt.split('')
      let numIntArrReverse = numIntArr.reverse()
      let arr = []
      numIntArrReverse.map((item, index) => {
        arr.push(item)
        if ((index + 1) % 3 == 0) {
          arr.push(',')
        }
      })
      return arr.reverse().join('') + '.' + numFloat
    }
  },
  mounted(){
        this.getGasCardAccBalanceList()
        this.getGasCardAllBalanceList()
        this.getGasCardczbBalanceList()
        this.getGasCardetsdBalanceList()
				this.getGasCardwjyBalanceList()

			  this.getGasCardLVBalance()
        this.getGasCardMonthRechargeBalance()
        this.getGasCardDayConsumeBalance()
				this.getGasCardMonthConsumeBalance()
				
				this.getGasRechargeAllBalance()
				this.getGasTransferAllBalance()

        // this.getGaslengendList()
  }
}
</script>
<style scoped lang="scss">
.card-title{
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
}
.card-body{
	width: 90%;
	flex-wrap: wrap;
	margin:0 auto;
	.box-card{
		width: 30%;
    margin: 15px;
    cursor: pointer;
	  .card-body-itemlist {
			align-items: center;
		  text-align: center;
		  color:rgb(64, 158, 255);
		  .card-body-item-name{
		  	font-size: 16px;
		  	font-weight: bold;
		  }
		  .card-body-item-money{
		  	font-size: 28px;
		  	font-weight: bold;
		  }
	  }
	  .card-body-itemlistSec {
			align-items: center;
		  text-align: center;
		  color: darkred;
			.card-body-item-name{
		  	font-size: 16px;
		  	font-weight: bold;
		  }
		  .card-body-item-money{
		  	font-size: 28px;
		  	font-weight: bold;
		  }
	  }
	  .card-body-itemlistThi {
			align-items: center;
		  text-align: center;
		  color: darkgreen;
		  .card-body-item-name{
		  	font-size: 16px;
		  	font-weight: bold;
		  }
		  .card-body-item-money{
		  	font-size: 28px;
		  	font-weight: bold;
		  }
	  }
	  .card-body-itemlistFou {
			align-items: center;
		  text-align: center;
		  color:burlywood;
		  .card-body-item-name{
		  	font-size: 16px;
		  	font-weight: bold;
		  }
		  .card-body-item-money{
		  	font-size: 28px;
		  	font-weight: bold;
		  }
	  }
	}
}
</style>