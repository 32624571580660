<template>
	<div class="dialog">
		  <el-dialog :title="dialogInfo" :visible.sync="dialogVisible" :before-close="cancelDialog" width="36%">
		    <slot>empty</slot>
		    <div class="dialog-footer" v-if="dialogFooter">
		      <el-button @click="cancelDialog">取 消</el-button>
		      <el-button type="primary" @click="confirmcancelDialog">确 定</el-button>
		    </div>
		  </el-dialog>
	</div>
</template>
<script>
  export default {
	   props: {
		    dialogVisible: {
		      type: Boolean,
		      default: false
		    },
		    dialogInfo: {
		      type: String,
		      default: ''
		    },
		    dialogFooter:{
		      type: Boolean,
		      default: true
		    }
	  },
	  watch: {
	　　//监听 弹窗显示， 可以用来写 请求接口
	    dialogVisible: function(newVal, oldVal) {
	      if (newVal) {
	        console.log(newVal);
	      }
	    }
	  },
	  
	  components: {},
	  name: "componentDialog",
	  data() {
	    return {
	    	
	    }
	  },
	  created() {},
	  mounted() {},
	  methods: {
	   //修改父组件传过来的值
	    cancelDialog() {
	      this.$emit("update:dialogVisible", false);
	    },
	    confirmcancelDialog() {
	      this.$emit("update:confirmdialogVisible", false);
	    },
	  }
  };
</script>
<style lang='scss' scoped>
.form{
    background: #eee;
    padding: 0 10px;
}
.dialog-footer{
    text-align: right;
    padding-top: 20px;
}

</style>
