import request from "@/utils/request";

/**
 * 获取加油订单列表
 */
export function getGasOrderList(address, nonumber, mobile, size, page) {
  return request.get("/oilCard/api/gas-orders", {
	  	params: {
			address:address,
	  		no:nonumber,
			mobile:mobile,
		  	size:size,
		  	page:page
		}
  });
}


/**
 * 获取加油订单详情信息
 */
export function getGasOrder(id) {
  return request.get("/oilCard/api/gas-order/"+id, {});
}
