<template>
  <div class="app-main">
  	 <div class="body">
				    <el-form inline class="my-form">	
				      <el-form-item label="加油卡号：">
				        <el-input size="small" placeholder="" v-model="gardNo"></el-input>
				      </el-form-item>
				      <el-form-item label="持卡方信息：">
				        <el-input size="small" placeholder="" v-model="fromRealname"></el-input>
				      </el-form-item>

					  <el-form-item label="手机号：">
				        <el-input size="small" placeholder="" v-model="phoneNumber"></el-input>
				      </el-form-item>

				      <el-form-item label="状态：">
		              <v-select :selectData="rechargeStatus"  :selectID="state"  @choonseGrageEvent="chooseStatus"></v-select> 
		           </el-form-item>
				      <el-form-item label="充值时间：">
				           <time-picker @timeValue="getValue(arguments)"></time-picker>
				      </el-form-item>
				      <el-form-item>
		              <el-button size="small" type="primary" @click.stop="findData">查询</el-button>
			            <el-button size="small" @click.stop="resetBtn">重置</el-button>
			            <el-button size="small">导出</el-button>
				      </el-form-item>
				    </el-form>
      </div>
      
			<div class="body m-t20">
			  	<page-table :data="tableData" :columns="columns" :pagination="pagination" v-if="cardType=='全部'" :loading="loading"  @size-change="pageSizeNum"  @current-change="currentPageNum">
			      <el-table-column label="操作" align="center">
			        <template slot-scope="scope">
			                <el-tag @click.stop="rechargeDetail(scope.$index, scope.row)" class="btnlist">详情</el-tag>
			        </template>
			      </el-table-column>
			   </page-table>
	  </div>
	  
  	<v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" :dialogFooter='dialogFooter' @update:dialogVisible="dialogVisibles">
  	 		   <div class="dialog_content" v-show="oildetailStatus" v-for="(item, index) in this.rechargeListDetail" :key="index">
	     	   	  <p>账户充值-：来自{{item.payment}}账户({{item.rechargeId}})</p>
	     	      <p>充值金额：¥{{item.price.toFixed(2)}}元</p>
	    	   	  <p>当前状态：
	   	   	   	     <span v-if="item.state==1">支付成功</span>
				           <span v-if="item.state==0">等待支付</span>
				           <span v-if="item.state==-1">已取消</span>
	    	   	  </p>
	    	   	  <p>充值时间：{{item.addTime}}</p>
	    	   	  <p>支付方式：{{item.payment}}</p>
	    	   	  <p>交易单号：{{item.no}}</p>
	     	   </div>
  	 	
    </v-dialog>
  </div>
</template>
<script>
  import { getRechargeList, getRecharge } from "@/api/recharge";
  import pageTable from '../../components/pageTable'
  import timePicker from '../../components/timePicker'
  import VDialog from '../../components/dialog'
  import VSelect from '../../components/select'
  export default {
    name: 'rechargeDetail',
    inject:['reload'],
    data() {
      return {
       	cardType: '全部',
        tableData: [],
        rechargeListDetail:[],
        rechargeStatus: [{
          value: 1,
          label: '充值成功'
        }, {
          value: 0,
          label: '等待支付',
    
        }, {
          value: -1,
          label: '已关闭'
        }],
        columns: [
          {
            name: '充值时间',
            value: 'addTime'
          },
          {
            name: '持卡方信息',
            value: 'user'
          },
          {
            name: '加油卡号',
            value: 'no'
          },
          {
            name: '手机号',
            value: 'rechargeTelPhone'
          },
          {
            name: '充值金额（元）',
            value: 'price'
          },
          {
            name: '状态',
            value: 'rechargeState'
          }
     
        ],
        loading:false,
        pagination: {
          layout: 'total,prev, pager, next, sizes, jumper',
          total: 0,
          pageSize: 10,
          pageSizes: [10, 20, 50, 100],
          currPage: 0,
          size: 0
        },
        dialogVisible:false,
        oildetailStatus:false,
        dialogInfo:'',
        dialogFooter:false,

	      startAddTime:'', 
	      endAddTime:'', 
	      gardNo:'', 
		  phoneNumber:'',
		  fromRealname:'', 
	      state:''
	      
      }
    },
    components: { pageTable,timePicker,VDialog,VSelect },
    created() {
        document.title = '提现管理'
        this.$store.dispatch('SetNavbar', [{ meta: { title: '管理中心' }, path: '/oilCard-manage/index' }, { meta: { title: '油卡管理' }, path: '/oilCard-manage/manage' }, { meta: { title: '流水记录' }, path: '/oilCard-manage/flow-detail' }, { meta: { title: '提现管理' } }])
    },
    methods:{
    	getValue(data){
 		     this.startAddTime=data[0]
	       this.endAddTime=data[1]
    	},
    	
    	findData(){
    	   this.getRechargeData(this.startAddTime, this.endAddTime, this.gardNo, this.fromRealname, this.phoneNumber, this.state, this.pagination.pageSize, this.pagination.currPage)
    	},
    	resetBtn(){
           var self=this
    	     self.reload () 
    	},

    	getRechargeData(startAddTime, endAddTime, gardNo, fromRealname,fromMobile, state, size, page){
 	        return new Promise((resolve, reject) => {
       	  this.loading = true;
		        getRechargeList(startAddTime ? startAddTime + " 00:00:00" : null, endAddTime ? endAddTime + " 23:59:59" : null, gardNo, fromRealname, fromMobile, state, size, page).then(res => {
		             this.loading = false;
			            if(res.code === 1){
			            	 this.tableData = res.content
			            	 this.pagination.total = res.totalElements
				             this.pagination.size = res.totalPages	
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		          })
		      })
      },
      rechargeDetail(id){
      	 return new Promise((resolve, reject) => {
       	  this.loading = true;
		        getRecharge(id).then(res => {
		             this.loading = false;
			            if(res.code === 1){
			            	 this.tableData = res.content
			            }
			            resolve(res)
		         }).catch(error => {
		         	   this.loading = false;
		             reject(error)
		          })
		      })
      },
      pageSizeNum(value){

      	  console.log(value)
      	  this.pagination.pageSize=value
      	  this.getRechargeData(this.startAddTime, this.endAddTime, this.gardNo, this.fromRealname, this.phoneNumber, this.state, this.pagination.pageSize, this.pagination.currPage)
      },
      currentPageNum(event, value){
      	  console.log(event)
      	  this.pagination.currPage=event
      	  this.getRechargeData(this.startAddTime, this.endAddTime, this.gardNo, this.fromRealname, this.phoneNumber, this.state, this.pagination.pageSize, this.pagination.currPage)
      },

      chooseStatus(value){
        	console.log(value)
        	this.state=value
      },
      rechargeDetail(event, value){
        this.rechargeListDetail=[value]
      	this.dialogVisible=true
        this.oildetailStatus=true
        this.dialogInfo='充值详情'
      	
      },
       //子组件传 过来的 数据
		  dialogVisibles(v){
		      this.dialogVisible = v
		  }
    },
    mounted(){
       this.getRechargeData(this.startAddTime, this.endAddTime, this.gardNo, this.fromRealname, this.phoneNumber, this.state, this.pagination.pageSize, this.pagination.currPage)
    }
  }
</script>
<style scoped lang="scss">
.btn_po{
	margin-bottom: 10px;
}
.el-form-item{
   margin:0 45px 0px 0;
}
.dialog_content p{
	line-height: 25px;
}
</style>