<template>
  <div class="app-main"> 
    <div class="body">
          <el-card class="box-card">
					  <div slot="header" class="clearfix">
					    <span class="title">油卡明细</span>
					  </div>
					  <page-table :data="tableData" :columns="columns" :pagination="pagination" :loading="loading" :showPagination="showPagination">
					      <el-table-column label="操作" align="center" >
			                <template slot-scope="scope">
					                     <el-tag  @click.stop="showDetail(scope.$index, scope.row)" class="btnlist">详情</el-tag>
					            </template>
					      </el-table-column>
			      </page-table>
					</el-card>
    </div>
    
  	<v-dialog :dialogVisible="dialogVisible" :dialogInfo="dialogInfo" :dialogFooter='dialogFooter' @update:dialogVisible="dialogVisibles">
  	 		   <div class="dialog_content" v-show="oildetailStatus" v-for="item in this.cardRecordDetail" >
	     	   	  <p>账户充值-：转给{{item.payment}}</p>
	     	      <p>转账金额：{{item.balance}}元</p>
	    	   	  <p>当前状态：
	   	   	   	     <span v-if="item.state==1">已成功</span>
				           <span v-if="item.state==0">未完成</span>
	    	   	  </p>
	    	   	  <p>接收单位：{{item.user.realname}}</p>
	    	   	  <p>接收账户：{{item.user.username}}</p>
	    	   	  <p>手续费：{{item.servicePrice}}元</p>
	    	   	  <p>转卡备注：{{item.remark}}</p>
	    	   	  <p>转卡时间：{{item.addTime}}</p>
	    	   	  <p>转卡单号：{{item.no}}</p>
	     	  </div>
   </v-dialog>
   </div>
  </div>
</template>
<script>
	import { getGasCard } from "@/api/card";
	import pageTable from '../../components/pageTable'
	import VDialog from '../../components/dialog'
  export default {
    name: 'manageDetail',
    data() {
      return {
      	tableData: [],
      	cardRecordDetail:[],
        columns: [
          {
            name: '序号',
            value:'serialNumber'
          },
          {
            name: '时间',
            value:'addTime'
          },
          {
            name: '内容',
            value:'user'
          },    
          {
            name: '收支（元）',
            value: 'used'
          },
          {
            name: '金额（元）',
            value:'balance'
          }
      
        ], 
        pagination: {
//        layout: 'total,prev, pager, next, sizes, jumper',
//        total: 0,
//        pageSize: 10,
//        pageSizes: [10, 20, 50, 100],
//        currPage: 0,
//        size: 0
        },
        loading: false,
        gasStationId:0,
        dialogVisible:false,
        oildetailStatus:false,
        dialogInfo:'',
        dialogFooter:false,
        showPagination:false
      }
    },
    components: { pageTable, VDialog },
    methods: {
      oilCardListDetail(gasStationID, size, page){
      	    var tableArr=[]
 	          return new Promise((resolve, reject) => {
	       	    this.loading = true;
			        getGasCard(gasStationID, size, page).then(res => {
			             this.loading = false;
				            if(res.code === 1){
				            	 tableArr.push(res.content)
				            	 this.tableData=tableArr
				            	 console.log(this.tableData)
//				             this.pagination.total = res.totalElements
//				             this.pagination.size = res.totalPages	
				            }
				            resolve(res)
			         }).catch(error => {
			         	   this.loading = false;
			             reject(error)
			          })
		        })
      },
//          
//    pageSizeNum(value){
//    	  console.log(value)
//    	  this.pagination.pageSize=value
//    	  this.oilCardListDetail(this.gasStationId,this.pagination.pageSize,this.pagination.currPage)
//    },
//    currentPageNum(event, value){
//    	  console.log(event)
//    	  this.pagination.currPage=event
//    	  this.oilCardListDetail(this.gasStationId,this.pagination.pageSize,this.pagination.currPage)
//    },
//    

      showDetail(event, value){
         this.cardRecordDetail=[value]
      	 this.dialogVisible=true
         this.oildetailStatus=true
         this.dialogInfo='转卡详情'

      },
      //子组件传 过来的 数据
		  dialogVisibles(v){
		      this.dialogVisible = v
		  }
    },
    filters: {
      money(n) {
        let num = n.toFixed(2)
        let numInt = num.split('.')[0]
        let numFloat = num.split('.')[1]
        let numIntArr = numInt.split('')
        let numIntArrReverse = numIntArr.reverse()
        let arr = []
        numIntArrReverse.map((item, index) => {
          arr.push(item)
          if ((index + 1) % 3 == 0) {
            arr.push(',')
          }
        })
        return arr.reverse().join('') + '.' + numFloat
      }
    },
    mounted(){
    	 this.gasStationId = this.$route.query.id
    	 console.log(this.$route.query.id)
       this.oilCardListDetail(this.gasStationId)
    }
  }
</script>
<style lang="scss" scoped>
  .head {
    background: #fff;
    display: flex;
    padding-left: 20px;
    align-items: center;
    height: 100px;
    .money {
      vertical-align: baseline;
      margin: 0 20px;
      span {
        font-size: 20px;
      }
    }
  }
  .el-form-item {
    margin-top: 22px;
    margin-bottom: 0;
  }
  .all-money {
    margin: 5px 0;
    font-size: 14px;
    color: #817979;
    text-align: right;
  }
  .title{
  	font-size: 17px;
  	font-weight: bold;
  }
  .text {
    font-size: 14px;
  }
  .item {
    margin-bottom: 18px;
  }
  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  }
</style>